import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Modal,
  Grid,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import BasicButtons from "../../../components/Button";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";


// Validation schema using Yup
const validationSchema = Yup.object().shape({
  subject: Yup.string().trim()
    .required("Subject is required.")
    .max(100, "Subject cannot exceed 100 characters."),
  message: Yup.string().trim()
    .required("Message is required.")
    .max(600, "Message cannot exceed 600 characters."),
});

const addEditStyle = {
  addEditLabel: {
    fontWeight: "bold",
  },
  addEditInfo: {
    fontWeight: "normal",
  },
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius:"20px"
};

const ViewTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const contactUs = location.state?.contactUs || null;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleReply = async (values, { resetForm }) => {
    try {
      const response = await postDataHandlerWithToken("sendEmail", {
        contactUsId: contactUs?._id,
        subject: values.subject,
        message: values.message,
        email: contactUs?.email,
      });
      if (response?.responseCode === 200) {
        toast.success("Reply sent successfully.");
        resetForm();
        handleClose();
        navigate("/ticketListing");
      } else {
        toast.error(response?.responseMessage || "Failed to send reply");
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error("Failed to send reply.");
    }
  };

  if (!contactUs) {
    return (
      <DashboardLayout sectionName="Ticket detail" title="Contact Us Not Found">
        <Box sx={{ padding: "2rem" }}>
          <Typography variant="h5">Contact Us data not found</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      sectionName="Contact Us Management"
      title="View Contact Us"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography variant="h4">View Ticket </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Name:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.fullName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Email:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Phone:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.phoneNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Status:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.status}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Message:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.message}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Solution:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.solution || "Not replied"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BasicButtons
                width={"40%"}
                text={"Reply"}
                onClick={handleOpen}
                disabled={contactUs?.status == "closed" ? true : false}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Modal for reply */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="reply-modal-title"
        aria-describedby="reply-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="reply-modal-title" variant="h5" component="h2">
            Reply to Ticket
          </Typography>

          {/* Formik form for replying */}
          <Formik
            initialValues={{ subject: "", message: "" }}
            validationSchema={validationSchema}
            onSubmit={handleReply}
          >
            {({ errors, touched, handleChange }) => (
              <Form>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <Typography sx={{...typographyStyles.h6}}>Subject</Typography>
                    <InputField
                      name="subject"
                      type="text"
                      maxLength={60}
                      onChange={handleChange}

                      placeholder="Enter subject"
                      className={`form-input ${touched.subject && errors.subject ? 'error' : ''}`}
                      style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                    />
                    {touched.subject && errors.subject && (
                           <p style={{ color: "red", marginTop: "2px" }}>
                        {errors.subject}
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{...typographyStyles.h6}}>Message</Typography>
                    <InputField
                      name="message"
                      as="textarea"
                      placeholder="Enter message"
                      maxLength={800}
                      onChange={handleChange}

                      className={`form-input ${touched.message && errors.message ? 'error' : ''}`}
                      rows={4}
                      style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                    />
                    {touched.message && errors.message && (
                     <p style={{ color: "red", marginTop: "2px" }}>
                        {errors.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={12} mt={2}  sx={{display:"flex" , alignItems:"center", justifyContent:"center"}} gap={2}>
                  
                    {/* <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleClose}
                      
                    >
                      Cancel
                    </Button> */}
                    <BasicButtons  color="secondary"  text="Cancel" width="25%" onClick={handleClose}/>
                    <BasicButtons type="submit"  text="Reply" width="25%"/>
                     
                    
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default ViewTicket;
