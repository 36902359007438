import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Divider,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
} from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import DataNotFound from "../../../components/DataNotFound";

import "../../../scss/main.css";
import { useNavigate, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import backBtn from "../../../assets/backBtn.svg";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import {
  //   DndProvider,
  DragDropContext,
  Draggable,
  Droppable,
} from "@hello-pangea/dnd";
import { DragHandle } from "@mui/icons-material";


const columns = [
 "Sr.No." ,
   "Stage" ,
  "Workout Subsection Name" ,

  "Created Date & Time" ,
   "Action" 
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(225,140,168)",
    color: "white",
  },
  cellStyle: {
    textAlign: "left",
    // fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
    height: "49px",
    fontSize: "0.875rem",
  },

  tableStyle: {
    textAlign: "left",
    color: "white",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
  },
};


const WorkoutSubCategory = () => {
  const [programList, setProgramList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [render, setRender] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [filteredTopicList, setFilteredTopicList] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [noData, setNoData] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setcurrentPage] = useState(1);
  const rowsPerPageOptions = [10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const shouldShowPagination = topicList?.length > rowsPerPage;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 12));
    setPage(0);
  };
  const fetchTopic = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken(
        "listWorkoutSubCategory",
        query
      );
      console.log("API response:", res);
      if (res.responseCode === 200) {
        setTopicList(res?.result?.docs || []);
        const programId = location?.state?.categoryId;
        console.log(programId);
        const filteredTopic = res?.result?.docs.filter(
          (program) => program.workoutCategoryId === programId,
        );
        console.log("API response:", filteredTopic);
        filteredTopic.length===0 ? setNoData(true):setNoData(false);

        setFilteredTopicList(filteredTopic);
        setFilteredData(filteredTopic);
      } else {
        setNoData(true);
      }
    } catch (error) {
      console.log(error);
      
    } finally {
      setLoading(false);
    }
  };



  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Reorder only the current page data (filteredData), not the entire dataset
    const currentPageData = [...filteredData];
    const [movedRow] = currentPageData.splice(source.index, 1);
    currentPageData.splice(destination.index, 0, movedRow);

    // Update the filteredData with the reordered rows
    setFilteredData(currentPageData);
    setTopicList(currentPageData);

    // Prepare the reordered data to be sent to the database, ensuring global indices are updated
    const updatedOrderArray = currentPageData.reverse().map((item, index) => ({
      workoutSubCategoryId: item._id,
      orderId: (index + 1).toString(),
    }));

    console.log("Reordered data to update:", updatedOrderArray);

    // Persist the new order to the database
     saveOrderToDB(updatedOrderArray);
  };

  const saveOrderToDB = async (updatedOrderArray) => {
    try {
      const data = { order: updatedOrderArray };

      const res = await putDataHandlerWithToken("updateWorkoutSubCategoryOrder", data);
      if (res?.responseCode === 200) {
        toast.success("Order updated successfully!");
        fetchTopic();
      } else {
        toast.error("Failed to update order");
      }
    } catch (error) {
      console.error("Error saving order to the database:", error);
      toast.error("An error occurred while updating order");
    }
  };

 
  useEffect(() => {
    fetchTopic();
  }, [render, toggle]);
  // useEffect(() => {
  //   //filteredTopic.length===0 ? setNoData(true):setNoData(false);
  //   setLoading(false);
  // }, [topicList]);
  const handleFilterChange = (filterCriteria) => {
    let filtered = filteredTopicList;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.workoutSubCategoryName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };

  const handleView = (id) => {
    navigate("/workoutlisting/workoutSubcategory/viewSubcategory", {
      state: { subcategoryId: id },
    });
  };

  const handleEdit = (id) => {
    navigate("/workoutlisting/workoutSubcategory/editSubcategory", {
      state: { subcategoryId: id },
    });
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    const query = {
      subCategoryId: id,
    };
  
    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockWorkoutSubCategory",
        {},
        query
      );
      console.log(res);
      if (res.responseCode === 200) {
        const updatedProgramList = topicList.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setTopicList(updatedProgramList);
  
        // Update the filteredData as well
        const updatedFilteredData = filteredData.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setFilteredData(updatedFilteredData);
  
        setBlockModalOpen(false);
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = filteredTopicList.map(({ _id, ...rest }) => rest);
    const headers = ["Sr.No.", "Stage","Workout Subsection Name",  "Created Date & Time"];

    const dataToExport = dataWithoutId.map((row, index) => ({
      sr_no: index + 1,
      
      stage: row.stage,
      Subcategory_name: row.workoutSubCategoryName,
      

      
      created_date_time: new Date(row.createdAt).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      status:row.status
    }));

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataToExport
    .map((row) =>
      columns
        .map((col) => {
          const value = row[col.id];
          return typeof value === "string" && value.includes(",")
            ? `"${value}"` // Escape commas in CSV values
            : value;
        })
        .join(",")
    )
    .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `WorkoutSubsection_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };
  console.log(filteredTopicList);
  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      title="Topic General Detials"
      showBackButtonSection={true}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{
                  // // width: "2.813",
                  // height: "5.813",
                  cursor: "pointer",
                  gap: "10px",
                }}
                onClick={() => navigate(-1)}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>
              Workout Subsection Listing
            </Typography>
          </Box>

          
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text=" +Add Subsection"
                onClick={() => {
                  navigate(
                    "/workoutlisting/workoutSubcategory/addSubcategory",
                    {
                      state: { categoryId: location.state.categoryId },
                    }
                  );
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Search by subsection name"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : noData ? (
            <DataNotFound />
          ) : (
            <TableContainer
            component={Paper} sx={{ maxHeight: "63vh", overflowY: 'auto' }}
          >
            {loading ? (
              <Loading />
            ) : (
              <>
                {filteredData?.length === 0 ? (
                  <DataNotFound />
                ) : (
                  <Table sx={{ minWidth: 800 }}>
                    <TableHead>
                      <TableRow sx={PaginationTableStyle.rowStyle}>
                        {columns.map((e, i) => (
                          <TableCell
                            key={i}
                            align="left"
                            sx={PaginationTableStyle.tableStyle}
                          >
                            {e}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="programList">
                        {(provided) => (
                          <TableBody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            
                          >
                            {filteredData
                             
                              ?.map((item, i) => {
                                // Define stageKeys and stageText here, outside of JSX
                                const stageKeys = se
                                  .filter((option) => item.stage.includes(option.value))
                                  .map((option) => option.key);
                                const stageText = stageKeys.join(", ") || "Unknown Stage";
          
                                return (
                                  <Draggable key={item?.id} draggableId={item?.id} index={i}>
                                    {(provided,snapshot) => (
                                      <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#faebf0"
                                            : "inherit",
                                          ...provided.draggableProps.style,
                                          width: "100%",
                                        }}
                                        sx={PaginationTableStyle.rowStyle}
                                        key={i}
                                      >
                                        <TableCell sx={PaginationTableStyle.cellStyle}>
                                          {currentPage
                                            ? (currentPage - 1) * 10 + (i + 1)
                                            : i + 1}
                                        </TableCell>
          
          
                                        <TableCell sx={PaginationTableStyle.cellStyle}>
                                          {stageText.length > 20
                                            ? `${stageText.substring(0, 20)}...`
                                            : stageText}
                                        </TableCell>


                                        <TableCell sx={PaginationTableStyle.cellStyle}>
                                          {item?.workoutSubCategoryName
                                            ? item?.workoutSubCategoryName
                                            : "NA"}
                                        </TableCell>
          
                                        <TableCell sx={PaginationTableStyle.cellStyle}>
                                          {item?.createdAt
                                            ? new Date(item?.createdAt).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              })
                                            : "NA"}
                                        </TableCell>
          
                                        <TableCell sx={PaginationTableStyle.cellStyle}>
                                          <VisibilityIcon
                                            variant="outlined"
                                            onClick={() => handleView(item._id)}
                                            sx={{
                                              color: "rgb(225,140,168)",
                                              borderColor: "rgb(225,140,168)",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <EditIcon
                                            onClick={() => handleEdit(item._id)}
                                            sx={{
                                              color: "rgb(225,140,168)",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily: "'Poppins', sans-serif",
                                              cursor: "pointer",
                                              ml:2,
                                              mr:2
                                            }}
                                          />
                                          <BlockIcon
                                            onClick={() =>
                                              handleBlock(item._id, item.workoutCategoryName, item.status)
                                            }
                                            sx={{
                                              color: item.status === "ACTIVE" ? "green" : "red",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily: "'Poppins', sans-serif",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <DragHandle sx={{ ml: 4 }} />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                )}
              </>
            )}

            {/* {shouldShowPagination && (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={filteredData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  "& .MuiTablePagination-selectIcon": {
                    color: "rgb(225,140,168)",
                  },
                }}
              />
            )} */}

            {/* {shouldShowPagination && (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={filteredData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  "& .MuiTablePagination-selectIcon": {
                    color: "rgb(225,140,168)",
                  },
                }}
              />
            )} */}
          </TableContainer>
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="SUBSECTION"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default WorkoutSubCategory;
