import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextareaAutosize,
  Button,
  Grid,
  Divider,
  Card,
  CardMedia,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	CodeBlock,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	GeneralHtmlSupport,
	Heading,
	HorizontalLine,
	Indent,
	IndentBlock,
	Italic,
	Link,
	Paragraph,
	SelectAll,
  List,
  ListProperties,
	TextTransformation,
	Underline,
	Undo
} from 'ckeditor5';
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import BasicButtons from "../../../components/Button";

import InputField from "../../../components/InputField";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";

import typographyStyles from "../../../theme/Typography";
import { recipeSchema } from "../../../schema";
import DeleteIcon from "@mui/icons-material/Delete";
import backBtn from "../../../assets/backBtn.svg";
import AddIcon from "@mui/icons-material/Add";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";

import { modalStyles } from "../../../scss/style"; // Ensure this path is correct
import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";
import Loading from "../../../components/Loading";

const editorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "style",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "bulletedList",
      "numberedList",

      "|",
      "horizontalLine",
      "link",
      "insertTable",
      "blockQuote",
      "codeBlock",
      "|",
      "alignment",
      "|",
      "outdent",
      "indent",
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    Indent,
    IndentBlock,
    Italic,
    Link,
    Paragraph,
    SelectAll,
    List,
    ListProperties,

    TextTransformation,
    Underline,
    Undo,
  ],
  fontFamily: {
    supportAllValues: true,
  },
  fontSize: {
    options: [10, 12, 14, "default", 18, 20, 22],
    supportAllValues: true,
  },
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true,
      },
    ],
  },

  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: "https://",
    decorators: {
      toggleDownloadable: {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "file",
        },
      },
    },
  },
  placeholder: "Type or paste your content here!",
};

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    padding: "5px 10px",
    fontWeight: "600",
    fontSize: "18px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "5px",
  },
  addRemoveButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "600",
    fontSize: "18px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
    marginLeft: "2rem",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(225,140,168)",
    },
  },
};

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditRecipe = () => {
  const location = useLocation();
  const [cuisineOptions, setCuisineOptions] = useState([
    { key: "", value: "" },
  ]);
  const [dietTypeOptions, setDietTypeOptions] = useState([
    { key: "", value: "" },
  ]);
  const [dietaryPreferenceOptions, setDietaryPreferenceOptions] = useState([
    { key: "", value: "" },
  ]);
  const [recipeTypeOptions, setRecipeTypeOptions] = useState([
    { key: "", value: "" },
  ]);

  const [thumbnail, setThumbnail] = useState(null);
  const [steps, setSteps] = useState([""]);
  const [nutrition, setNutrition] = useState([{ key: "", value: "" }]);
  const [editorContentIngredient, setEditorContentIngredient] = useState("");
  const [editorContentNotes, setEditorContentNotes] = useState("");

  const [recipeData, setRecipeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [charCount1, setCharCount1] = useState(0);
  const [charCount2, setCharCount2] = useState(0);
  const[fetch, setFetch]= useState(false);


  // States for cropping functionality
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchCuisine = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        console.log(res);
        if (res.responseCode === 200) {
          // Transform data to key-value pairs
          const transformedData = res.result.docs.map((item) => ({
            key: item.cuisineName, // Assuming 'name' is the property holding the name
            value: item._id, // Assuming 'id' is the property holding the id
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchDietary = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        console.log(res);
        if (res.responseCode === 200) {
          // Transform data to key-value pairs
          const transformedData = res.result.docs.map((item) => ({
            key: item.dietaryName, // Assuming 'name' is the property holding the name
            value: item._id, // Assuming 'id' is the property holding the id
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchDiet = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        console.log(res);
        if (res.responseCode === 200) {
          // Transform data to key-value pairs
          const transformedData = res.result.docs.map((item) => ({
            key: item.dietName, // Assuming 'name' is the property holding the name
            value: item._id, // Assuming 'id' is the property holding the id
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchRecipe = async (dataType, setData) => {
      const recipeIdFromLocation = location.state?.recipeId;
      const query = { recipeId: recipeIdFromLocation };

      try {
        setFetch(true)
        const res = await getDataHandlerWithToken(dataType, query);
        if (res.responseCode === 200) {
          setData(res.result || {});
          setThumbnail(res?.result.recipeThumbnailImage);
          setNutrition(res.result?.nutrition || []);
          setSteps(res.result?.steps || []);
          setEditorContentIngredient(res.result?.ingredientDetails || "");
          setEditorContentNotes(res.result?.notes || "");
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally{
        setFetch(false)
      
      }
    };
    const fetchRecipeType = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        console.log(res);
        if (res.responseCode === 200) {
          // Transform data to key-value pairs
          const transformedData = res?.result?.recipeTypes?.map((item) => ({
            key: item.recipeType, // Assuming 'name' is the property holding the name
            value: item._id, // Assuming 'id' is the property holding the id
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDietary("listDietary", setDietaryPreferenceOptions);
    fetchCuisine("listCuisines", setCuisineOptions);
    fetchDiet("getDietList", setDietTypeOptions);
    fetchRecipe("viewRecipe", setRecipeData);
    fetchRecipeType("listRecipeType", setRecipeTypeOptions);
  }, []);
  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));

      setIsModalOpen(true);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Image cropping functions
  const handleCrop = useCallback(async () => {
    setLoading(true);
    try {
      const cloudinaryUrl = await cropAndUploadImage(
        selectedImage,
        croppedAreaPixels
      );
      setCroppedImage(cloudinaryUrl);
      setSelectedImage(cloudinaryUrl);
      setThumbnail(cloudinaryUrl);
      setIsModalOpen(false);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    }
    setLoading(false);
  }, [croppedAreaPixels, selectedImage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImage(null);
    setThumbnail(null);
  };

  const navigate = useNavigate();

  console.log(recipeData?.dietaryId);

  const initialValues = {
    selectStage: recipeData?.stage || [],
    dietaryPreference: recipeData?.dietaryId || [],
    recipeType: recipeData?.recipeType || [],

    cuisine: recipeData?.cuisineId || [],
    dietType: recipeData?.dietId || [],
    preparationTime: recipeData?.preparationTime,
    thumbnailImage: thumbnail || "",
    ingredientDetails: recipeData?.ingredientDetails || "",
    servings: recipeData?.servings || "",

    nutrition: nutrition,
    recipeName: recipeData?.recipeName,
    steps: recipeData?.steps,
  };

  console.log(initialValues);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: recipeSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const formattedNutrition = nutrition.map((item) => ({
        key: item.key,
        value: item.value,
      }));

      const dataToSubmit = {
        recipeId: recipeData?._id,
        stage: values.selectStage,
        preparationTime: values.preparationTime,
        dietaryId: values.dietaryPreference,
        recipeType: values.recipeType,
        cuisineId: values.cuisine,
        dietId: values.dietType,
        recipeThumbnailImage: thumbnail,
        steps: steps.filter((step) => step.trim() !== ""),
        nutrition: formattedNutrition,
        recipeName: values.recipeName,
        ingredientDetails: values.ingredientDetails,
        servings: values.servings,
        notes: values.notes,
      };
      console.log(dataToSubmit);
      try {
        setLoading(true);
        const response = await postDataHandlerWithToken(
          "addUpdaterecipe",
          dataToSubmit
        );
        toast.success("Recipe edited successfully.");
        navigate("/recipelisting");
      } catch (error) {
        console.error("Error updating recipe:", error);
        toast.error("Failed to update recipe. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleDropdownChange = (value, dropdownName) => {
    setFieldValue(dropdownName, value);
  };
  const handleNutritionChange = (index, keyOrValue, value) => {
    const newNutrition = [...nutrition];
    newNutrition[index][keyOrValue] = value;
    setNutrition(newNutrition);
  };

  const handleStepChange = (index, value, dataType, setDataType) => {
    const newData = [...dataType];
    newData[index] = value;
    setDataType(newData);
  };

  const handleAddValue = (dataType, setDataType) => {
    setDataType([...dataType, ""]);
  };

  const handleRemoveStep = (index, dataType, setDataType) => {
    const newData = [...dataType];
    newData.splice(index, 1);
    setDataType(newData);
  };

  if (fetch) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh'  // Takes the full viewport height
      }}>
        <Loading  />
      </div>
    );
  }

  return (
    <DashboardLayout sectionName="Diet Type Management" title="Edit Recipe">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Recipe</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="selectStage"
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={se}
                  value={values.selectStage}
                  onChange={(value) =>
                    handleChange({ target: { name: "selectStage", value } })
                  }
                />
                {errors.selectStage && touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Recipe Type:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="recipeType"
                  sx={addEditStyle.categoryInputField}
                  options={recipeTypeOptions}
                  value={values.recipeType}
                  placeholder="Select Recipe Type"
                  onChange={(value) =>
                    handleChange({
                      target: { name: "recipeType", value },
                    })
                  }
                />
                {errors.recipeType && touched.recipeType && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.recipeType}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Dietary Preference
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="dietaryPreference"
                  sx={addEditStyle.categoryInputField}
                  options={dietaryPreferenceOptions}
                  value={values.dietaryPreference}
                  onChange={(value) =>
                    handleChange({
                      target: { name: "dietaryPreference", value },
                    })
                  }
                />
                {errors.dietaryPreference && touched.dietaryPreference ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.dietaryPreference}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Cuisine</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="cuisine"
                  sx={addEditStyle.categoryInputField}
                  options={cuisineOptions}
                  value={values.cuisine}
                  onChange={(value) =>
                    handleChange({ target: { name: "cuisine", value } })
                  }
                />
                {errors.cuisine && touched.cuisine ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.cuisine}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Diet Type
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="dietType"
                  sx={addEditStyle.categoryInputField}
                  options={dietTypeOptions}
                  value={values.dietType}
                  onChange={(value) =>
                    handleChange({ target: { name: "dietType", value } })
                  }
                />
                {errors.dietType && touched.dietType ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.dietType}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Recipe Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="recipeName"
                  value={values.recipeName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter recipe name "
                />
                {errors.recipeName && touched.recipeName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.recipeName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Thumbnail:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  acceptedFile="image/*"
                  onChange={handleThumbnailChange}
                />
                {thumbnail && (
                  <Box>
                    <Card
                      sx={{ maxWidth: 300, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnail} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Preparation Time (in minutes):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="preparationTime"
                  value={values.preparationTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter preparation time"
                />
                {errors.preparationTime && touched.preparationTime ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.preparationTime}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  No. of servings:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  placeholder="Enter no. of servings"
                  name="servings"
                  type="number"
                  value={values.servings}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.servings && errors.servings)}
                  helperText={touched.servings && errors.servings}
                />
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Ingredient Details:
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={editorContentIngredient}
                  config={editorConfig}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setFieldValue("ingredientDetails", data);
                    const plainText = data.replace(/<[^>]*>/g, "");
                    setCharCount1(plainText.length);
                  }}
                />
                 {errors.ingredientDetails && touched.ingredientDetails && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.ingredientDetails}
                  </p>
                )}

<Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: "right",
                    mt: 1,
                    color: charCount1 > 2400 ? "red" : "inherit",
                  }}
                >
                  {charCount1}/{2400} characters
                </Typography>
              </Box>
            </Box>
            <Box className="add-edit-input-container" sx={{ width: "100%" }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Steps:</Typography>
              </Box>
              <Box className="add-edit-input-field" sx={{ width: "100%" }}>
                {steps.map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                      width: "100%",
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <InputField
                        placeholder={`Step ${index + 1}`}
                        value={step}
                        onChange={(e) =>
                          handleStepChange(
                            index,
                            e.target.value,
                            steps,
                            setSteps
                          )
                        }
                        fullWidth
                      />
                    </Box>
                    <Box>
                      {index === steps.length - 1 ? (
                        <IconButton
                          variant="contained"
                          onClick={() => handleAddValue(steps, setSteps)}
                          sx={addEditStyle.addRemoveButton}
                        >
                          <AddCircleOutlineRoundedIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() =>
                            handleRemoveStep(index, steps, setSteps)
                          }
                          sx={addEditStyle.addRemoveButton}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className="add-edit-input-container" sx={{ width: "101%" }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Nutrition:
                </Typography>
              </Box>
              <Box className="add-edit-input-field" sx={{ width: "100%" }}>
                {nutrition.map((item, index) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item xs={5}>
                      <InputField
                        placeholder={`Nutrition Name`}
                        value={item.key}
                        onChange={(e) =>
                          handleNutritionChange(index, "key", e.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <InputField
                        placeholder={`Nutritional value `}
                        value={item.value}
                        onChange={(e) =>
                          handleNutritionChange(index, "value", e.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {index === nutrition.length - 1 ? (
                        <IconButton
                          variant="contained"
                          onClick={() =>
                            handleAddValue(nutrition, setNutrition)
                          }
                          sx={addEditStyle.addRemoveButton}
                        >
                          <AddCircleOutlineRoundedIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() =>
                            handleRemoveStep(index, nutrition, setNutrition)
                          }
                          sx={addEditStyle.addRemoveButton}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Notes(if any):
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={editorContentNotes}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("notes", data);
                    const plainText = data.replace(/<[^>]*>/g, "");
                    setCharCount2(plainText.length);
                  }}
                  config={editorConfig}
                />
                {errors.notes && touched.notes && (
                 <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                 {errors.notes}
               </p>
                )}

<Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: "right",
                    mt: 1,
                    color: charCount2 > 2400 ? "red" : "inherit",
                  }}
                >
                  {charCount2}/{2400} characters
                </Typography>
              </Box>
            </Box>
            <Box
              className="add-edit-button-section"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
               <BasicButtons
                text="Back"
                width={"25%"}
                onClick={() => {
                  navigate("/recipelisting");
                }}
              />
              <BasicButtons
                text={"Edit Recipe"}
                disabled={loading}
                name="submit"
                type="submit"
                loading={loading}
                width={"25%"}
              />
             
            </Box>
          </Box>
        </form>
      </Box>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={17 / 12}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default EditRecipe;
