import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardMedia,
  CardActions,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import ApiConfig from "../../../apiconfig/apiconfig";
import { commonGridStyle } from "../../../scss/style";
import DyntubePlayer from "../../../components/DynTube";
import Loading from "../../../components/Loading";

const addEditStyle = {
  addEditLabel: {
    fontWeight: "bold",
  },
  addEditInfo: {
    fontWeight: "normal",
    wordBreak: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "70%",
  },
};

const ViewTopic = () => {
  const [program, setProgram] = useState(null);
  const [fetch, setfetch] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const query = {
      topicId: location.state?.topicId,
    };
    try {
      setfetch(true);
      const res = await getDataHandlerWithToken("viewTopic", query);
      if (res.responseCode === 200) {
        setProgram(res.result);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      toast.error(error.message || "Error fetching data");
    } finally {
      setfetch(false);
    }
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  const BASEURL = ApiConfig.navigationUrl;
  const handleWebNavigate = () => {
    const itemId = location?.state?.topicId;
    const categoryId = program?.categoryId;
    const url = `${BASEURL}/program/programs/topic/description/${itemId}?categoryId=${categoryId}&getTopic=true`;
    window.open(url, "_blank");
  };

  if (fetch) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Takes the full viewport height
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      title="Topic General Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Topic</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box sx={{ width: "30%" }}>
            <BasicButtons text="View on website" onClick={handleWebNavigate} />
          </Box>
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Topic Name
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {program?.topicName}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Thumbnail Image
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={program?.topicThumbnailImage}
                        />
                      </Card>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Content Details:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {program?.contentDetails?.length > 0 ? (
                        program.contentDetails.map((section, sectionIndex) => (
                          <Box
                            key={sectionIndex}
                            sx={{ marginBottom: "20px", ...commonGridStyle }}
                          >
                            <Typography sx={{ ...typographyStyles.h6 }}>
                              Description:
                            </Typography>
                            {section.description ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: section.description,
                                }}
                                style={{
                                  padding: "10px",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "4px",
                                  backgroundColor: "#fff",
                                  marginBottom: "10px",
                                }}
                              />
                            ) : (
                              <Typography>No description available</Typography>
                            )}

                            <Typography sx={{ ...typographyStyles.h6 }}>
                              Images:
                            </Typography>
                            {section.images?.length > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 1,
                                  borderRadius: "4px",
                                  backgroundColor: "#fff",
                                  marginBottom: "10px",
                                }}
                              >
                                {section.images.map((img, imgIndex) => (
                                  <Card key={imgIndex} sx={{ maxWidth: 150 }}>
                                    <CardMedia
                                      component="img"
                                      image={img.image}
                                      alt={`Image ${imgIndex + 1}`}
                                    />
                                    {img.redirectUrl && (
                                      <CardActions>
                                        <BasicButtons
                                          text="Redirection URL"
                                          size="small"
                                          onClick={() => {
                                            if (img.redirectUrl) {
                                              window.open(
                                                img.redirectUrl,
                                                "_blank",
                                                "noopener,noreferrer"
                                              );
                                            }
                                          }}
                                        >
                                          View Link
                                        </BasicButtons>
                                      </CardActions>
                                    )}
                                  </Card>
                                ))}
                              </Box>
                            ) : (
                              <Typography>No images available</Typography>
                            )}

                            <Table>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      padding: "12px",
                                      border: "1px solid #ddd",
                                      backgroundColor: "rgb(225,140,168)",
                                      width: "50%",
                                    }}
                                  >
                                    Title
                                  </th>
                                  <th
                                    style={{
                                      padding: "12px",
                                      border: "1px solid #ddd",
                                      backgroundColor: "rgb(225,140,168)",
                                      textAlign: "center",
                                    }}
                                  >
                                    Video
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {section.videos?.length > 0 ? (
                                  section.videos.map((item, index) => {
                                    const videoKeyMatch =
                                      item.videoScript?.match(
                                        /data-dyntube-key="([^"]+)"/
                                      );
                                    const videoKey = videoKeyMatch
                                      ? videoKeyMatch[1]
                                      : "";

                                    return (
                                      <TableRow
                                        key={item._id}
                                        style={
                                          index % 2 === 0
                                            ? { backgroundColor: "#f0f0f0" }
                                            : {}
                                        }
                                      >
                                        <TableCell
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {item.title || "No title available"}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            textAlign: "center",
                                          }}
                                        >
                                          {videoKey ? (
                                            <DyntubePlayer
                                              videoKey={videoKey}
                                            />
                                          ) : (
                                            <Typography>
                                              No video available
                                            </Typography>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      colSpan={2}
                                      style={{ textAlign: "center" }}
                                    >
                                      No videos available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </tbody>
                            </Table>

                            {/* Accordian Features Section */}
                            <Table sx={{ marginTop: "20px" }}>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      padding: "12px",
                                      border: "1px solid #ddd",
                                      backgroundColor: "rgb(173,216,230)",
                                      width: "50%",
                                    }}
                                  >
                                    Accordian Title
                                  </th>
                                  <th
                                    style={{
                                      padding: "12px",
                                      border: "1px solid #ddd",
                                      backgroundColor: "rgb(173,216,230)",
                                      textAlign: "center",
                                    }}
                                  >
                                    Accordian Description
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {section.accordianFeatures?.length > 0 ? (
                                  section.accordianFeatures.map(
                                    (feature, index) => (
                                      <TableRow
                                        key={index}
                                        style={
                                          index % 2 === 0
                                            ? { backgroundColor: "#f0f0f0" }
                                            : {}
                                        }
                                      >
                                        <TableCell
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {feature.title ||
                                            "No title available"}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            padding: "12px",
                                            border: "1px solid #ddd",
                                            textAlign: "center",
                                          }}
                                        >
                                          {section.description ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: feature.description,
                                              }}
                                              style={{
                                                padding: "10px",
                                              }}
                                            />
                                          ) : (
                                            <Typography>
                                              No description available
                                            </Typography>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      colSpan={2}
                                      style={{ textAlign: "center" }}
                                    >
                                      No features available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </tbody>
                            </Table>
                          </Box>
                        ))
                      ) : (
                        <Typography>No content available</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewTopic;
