import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";
import { recipeTypeSchema } from "../../../schema";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
};

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const AddRecipeType = () => {
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    console.log("Selected Recipe Type:", values.recipeType);
    console.log("Selected Stages:", values.selectStage);

    const data = {
      stage: values.selectStage,
      recipeType: values.recipeType,
    };

    try {
      setLoading(true);
      const res = await postDataHandlerWithToken("addUpdateRecipeType", data);

      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        Navigate("/recipeType");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add cuisine preference.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectStage: [],
      recipeType: "",
    },
    validationSchema: recipeTypeSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  return (
    <DashboardLayout
      sectionName="Cuisine Preference Management"
      title="Add Cuisine"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => Navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Recipe Type</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="selectStage"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={se}
                  value={formik.values.selectStage}
                  placeholder="Select stage"
                  onChange={(value) =>
                    formik.setFieldValue("selectStage", value)
                  }
                />
                {formik.errors.selectStage && formik.touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Recipe Type:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="recipeType"
                  value={formik.values.recipeType}
                  maxLength={50}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter recipe type"
                />
                {formik.errors.recipeType && formik.touched.recipeType ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.recipeType}
                  </p>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Add Recipe Type"
              name="submit"
              type="submit"
              width={"20%"}
              disabled={formik.isSubmitting}
              loading={loading}
            />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddRecipeType;
