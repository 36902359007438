import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import BasicButtons from "../../../components/Button";
import InputField from "../../../components/InputField";
import toast from "react-hot-toast";
import typographyStyles from "../../../theme/Typography";
import { CouponSchema } from "../../../schema";
import backBtn from "../../../assets/backBtn.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  postDataHandler,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "600",
    fontSize: "18px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
  },
};

const AddCoupon = () => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();

  const initialValues = {
    couponCode: "",
    discount: "",
    maxUses: "",
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const validationSchema = CouponSchema;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const unixTimestamp = selectedDate ? dayjs(selectedDate).unix() : null;
      const data = {
        couponCode: values.couponCode,
        discount: values.discount,
        maxUses: values.maxUses,
        validity: unixTimestamp,
      };

      try {
        setLoading(true);
        const response = await postDataHandlerWithToken("createCoupon", data);
        if (response?.responseCode === 200) {
          toast.success(response?.responseMessage);
          navigate("/couponListing");
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleNumberChange = (event, fieldName) => {
    const { value } = event.target;
    if (value >= 0) {
      formik.setFieldValue(fieldName, value);
    } else {
      formik.setFieldValue(fieldName, 0);
    }
  };

  return (
    <DashboardLayout sectionName="Add Coupon" title="Coupon">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Coupon</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Coupon Code:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="couponCode"
                  value={formik.values.couponCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter coupon code"
                  maxLength={30}
                />
                {formik.errors.couponCode && formik.touched.couponCode ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.couponCode}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Discount(%):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="discount"
                  maxLength={3}
                  value={formik.values.discount}
                  onChange={(event) => handleNumberChange(event, "discount")}
                  onBlur={formik.handleBlur}
                  placeholder="Enter discount percentage"
                />
                {formik.errors.discount && formik.touched.discount ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.discount}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Maximum Uses:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="maxUses"
                  value={formik.values.maxUses}
                  onChange={(event) => handleNumberChange(event, "maxUses")}
                  onBlur={formik.handleBlur}
                  placeholder="Enter maximum uses (default: Unlimited)"
                />
                {formik.errors.maxUses && formik.touched.maxUses ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.maxUses}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Validity (Date):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    value={selectedDate}
                    onChange={handleDateChange}
                    minDate={dayjs()} 
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box
              className="add-edit-button-section"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <BasicButtons
                text={"Add Coupon"}
                disabled={loading}
                name="submit"
                type="submit"
                width={"25%"}
                loading={loading}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddCoupon;
