import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import BasicButtons from "../../../components/Button";
import ApiConfig from "../../../apiconfig/apiconfig"

import { commonGridStyle } from "../../../scss/style";
import DyntubePlayer from "../../../components/DynTube";
import Loading from "../../../components/Loading";
const BASEURL = ApiConfig.navigationUrl;


const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },

  addEditLabel: {
    ...typographyStyles.h6,
    whiteSpace: "nowrap",
  },

  addEditInfo: {
    fontWeight: "normal",
    wordBreak: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  radioContainer: {
    display: "flex",
  },
};

const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const ViewSubcategory = () => {
  const [subCategory, setSubcategory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [fetch, setfetch]= useState(false)

  const fetchProgram = async () => {
    const query = {
      subcategoryId: location.state.subcategoryId,
    };
    try {
      setfetch(true)
      const res = await getDataHandlerWithToken(
        "viewWorkoutSubCategory",
        query
      );
      console.log(res?.result);
      if (res.responseCode === 200) {
        setSubcategory(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }finally{
      setfetch(false)

    }
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  const handleWebNavigate = () => {
    const itemId = location?.state?.subcategoryId;
    const categoryId = subCategory?.categoryId;
    const itemName = JSON.stringify(subCategory?.workoutSubCategoryName);
    const thumbnail = JSON.stringify(subCategory?.image);
    const url = `${BASEURL}/program/programs/topic/description/${itemId}?categoryId=${categoryId}&showExerciseGrid=true&thumbnail=${thumbnail}&itemName=${itemName}`;
    window.open(url, "_blank");
  };

  if (fetch) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh'  // Takes the full viewport height
      }}>
        <Loading/>
      </div>
    );
  }
  

  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      title="Topic General Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            View Workout Subsection
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box sx={{ width: "30%" }}>
            {" "}
            <BasicButtons text="View on website" onClick={handleWebNavigate} />
          </Box>

          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Workout Subsection Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.workoutSubCategoryName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Thumbnail Image:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.image?.map((preview, index) => (
                        <Grid item xs={3} key={index}>
                          <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                            <CardMedia
                              component="img"
                              image={preview}
                              alt={`Preview ${index}`}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Description:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.description ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: subCategory?.description,
                          }}
                          style={{ padding: "10px" }}
                        />
                      ) : (
                        <Typography>No description available</Typography>
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Exercises Section:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.exerciseSections?.map(
                        (section, sectionIndex) => (
                          <Box
                            key={sectionIndex}
                            sx={{ marginBottom: "20px", ...commonGridStyle }}
                          >
                                <Typography sx={{...typographyStyles.h6}}>
                              Description:
                            </Typography>
                            {section.description ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: section.description,
                                }}
                                style={{  padding: "10px",
                                border: "1px solid #e0e0e0", // Light border around description
                                borderRadius: "4px",          // Rounded corners for description
                                backgroundColor: "#fff",      // White background for description
                                marginBottom: "10px"  }}
                              />
                            ) : (
                              <Typography>No description available</Typography>
                            )}

                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      padding: "12px",
                                      border: "1px solid #ddd",
                                      backgroundColor: "rgb(225,140,168)",
                                      width:"50%"
                                    }}
                                  >
                                    Title
                                  </th>
                                  <th
                                    style={{
                                      padding: "12px",
                                      border: "1px solid #ddd",
                                      backgroundColor: "rgb(225,140,168)",
                                      textAlign: "center",
                                    }}
                                  >
                                    Video
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {section.exercises.map((item, index) => {
                                  // Extract the video key using regex
                                  const videoKeyMatch = item?.video?.match(
                                    /data-dyntube-key="([^"]+)"/
                                  );
                                  const videoKey = videoKeyMatch
                                    ? videoKeyMatch[1]
                                    : "";

                                  return (
                                    <tr
                                      key={item._id}
                                      style={
                                        index % 2 === 0
                                          ? { backgroundColor: "#f0f0f0" }
                                          : {}
                                      }
                                    >
                                      <td
                                        style={{
                                          padding: "12px",
                                          border: "1px solid #ddd",
                                        }}
                                      >
                                        {item.title}
                                      </td>
                                      <td
                                        style={{
                                          padding: "12px",
                                          border: "1px solid #ddd",
                                          textAlign: "center",
                                        }}
                                      >
                                        {videoKey && (
                                         
                                            <DyntubePlayer videoKey={videoKey} />
                                         
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Box>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewSubcategory;
