import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Chip, // Import Chip for tag-like appearance
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

const MultiSelectDropdown = ({
  options,
  value = [],
  onChange,
  placeholder,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(Array.isArray(value) ? value : []);
  }, [value]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(value);
    onChange(value);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Select
        labelId="checkbox-dropdown-label"
        id="checkbox-dropdown"
        open={open}
        onClose={handleToggle}
        onOpen={handleToggle}
        value={selectedOptions}
        onChange={handleSelect}
        IconComponent={(props) => (
          <ArrowDropDownIcon
            {...props}
            style={{ color: "rgba(245, 140, 169, 1)", fontSize: "1.875rem" }}
          />
        )}
        displayEmpty
        multiple
        renderValue={(selected) => {
          if (!Array.isArray(selected) || selected.length === 0) {
            return (
              <span style={{ color: "#afafaf" }}>
                {placeholder}
              </span>
            );
          }
          return (
            // Added flexbox for wrapping selected items
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
              {selected.map((val) => {
                const option = options.find((opt) => opt.value === val);
                return (
                  option && (
                    // Using Chip to display selected values as tags
                    <Chip
                      key={option.value}
                      label={option.key}
                      style={{
                        backgroundColor: '#faebf0',
                        borderRadius: '16px',
                       
                      }}
                    />
                  )
                );
              })}
            </div>
          );
        }}
        input={<OutlinedInput />}
        sx={{
          borderRadius: "5px",
          maxWidth: "100%",
          width: "100%",
          // Set a minimum height to keep the select box consistent
          "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
           // Ensure a minimum height even when nothing is selected
           
            padding: "12px", // Added padding for better spacing
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              marginTop: "8px",
              padding: "0px",
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedOptions.indexOf(option.value) > -1} />
            <ListItemText
              primary={option.key}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "13px",
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
