import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Divider, Typography } from "@mui/material";
import DashboardCard from "../../../components/Cards/DashboardCard";
import img from "../../../assets/dashboardlogo.png";
import PeopleIcon from "@mui/icons-material/People";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import QuizIcon from "@mui/icons-material/Quiz";
import AppsIcon from "@mui/icons-material/Apps";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import typographyStyles from "../../../theme/Typography";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { TryOutlined } from "@mui/icons-material";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import RateReviewIcon from "@mui/icons-material/RateReview";
import Loading from "../../../components/Loading";

const dashboardStyle = {
  dashboardContainer: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  cardSection: {
    // border: '2px solid green',
    display: "flex",
    flexWrap: "wrap",
    gap: "2%",
  },
  cardBox: {
    // border: '2px solid pink',
    margin: "10px 0px",
    minWidth: "32%",
  },
};

const Dashboard = () => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      const res = await getDataHandlerWithToken("getDashboardData");
      if (res.responseCode == 200) {
        setCardData([
          {
            cardIcon: PeopleIcon,
            title: "Total Users",
            count: res?.result?.user,
            navigate: "/mumListing",
          },
          {
            cardIcon: AppsIcon,
            title: "Total Programs",
            count: res?.result?.category,
            navigate: "/category",
          },
          {
            cardIcon: QuizIcon,
            title: "Total Queries",
            count: res?.result?.queries,
            navigate: "/ticketListing",
          },
          {
            cardIcon: RateReviewIcon,
            title: "Total Testimonials",
            count: res?.result?.testimonial,
            navigate: "/staticcontent/testimonialmanagement",
          },

          {
            cardIcon: RestaurantIcon,
            title: "Total Recipes",
            count: res?.result?.recipes,
            navigate: "/recipelisting",
          },

          {
            cardIcon: PeopleIcon,
            title: "Total Bookings",
            count: res?.result?.bookings,
            navigate: "/bookingListing",
          },
        ]);
      }
    } catch (error) {
      console.log("Error while gettin data.", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <DashboardLayout sectionName="Dashboard" showBackButtonSection={false}>
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Typography sx={{ ...typographyStyles.h4 }}>Dashboard</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={dashboardStyle.dashboardContainer}>
        <Box sx={dashboardStyle.cardSection}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
                width: "100%",
              }}
            >
              <Loading />
            </Box>
          ) : (
            cardData.map((card, index) => (
              <Box key={index} sx={dashboardStyle.cardBox}>
                <DashboardCard
                  cardIcon={card.cardIcon}
                  title={card.title}
                  count={card.count}
                  navigate={card.navigate}
                />
              </Box>
            ))
          )}
        </Box>
        <Divider />
        <Box></Box>
      </Box>
    </DashboardLayout>
  );
};

export default Dashboard;
