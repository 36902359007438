import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { addServiceSpecializationSchema } from "../../../schema"; // You need to define this schema
import "../../../scss/main.css";
import toast from "react-hot-toast";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const typeOptions = [
  { key: "Specialization", value: "SPECIALIZATION" },
  { key: "Service", value: "SERVICE" },
];

const EditServiceAndSpecialization = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming you pass an ID to edit the specific service/specialization

  const initialValues = {
    name: "",
  };
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addServiceSpecializationSchema, // You need to define this schema
    onSubmit: async (values) => {
      console.log(values);
      try {
        setLoading(true);
        // API call logic should go here
        const res = await postDataHandlerWithToken(
          "addUpdateServiceOrSpecialization",
          {
            id: id,
            type: "SERVICE",
            name: values.name,
          }
        );
        if (res.responseCode === 200) {
          toast.success(res.responseMessage);
          navigate("/serviceAndSpecialization");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        console.error("Error while updating:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const getServiceOrSpecialization = async () => {
    try {
      const res = await getDataHandlerWithToken("getServiceOrSpecialization", {
        id,
      });
      if (res.responseCode === 200) {
        console.log(res);
        const data = res.result;
        // setFieldValue(
        //   "selectType",
        //   typeOptions.find((option) => option.value == data.type).key
        // );
        setFieldValue("name", data.name);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getServiceOrSpecialization();
  }, []);

  return (
    <DashboardLayout
      sectionName="Service Management - Category"
      title="Edit Service "
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Service</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Name:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="name"
                  value={values.name}
                  maxLength={101}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter name"
                />
                {errors.name && touched.name ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.name}
                  </p>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Update"
              name="submit"
              type="submit"
              width={"30%"}
              loading={loading}
            />
            {/* <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => {
                navigate("/servicelisting");
              }}
            /> */}
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditServiceAndSpecialization;
