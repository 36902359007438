import { Box, Typography } from "@mui/material";
import React from "react";
import cardIcon from '../../assets/websitelogo.png';
import { useNavigate } from "react-router-dom";

const dashboardCardStyle = {
    dashboardCardContainer: {
        // border: '2px solid green',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        padding: '20px',
        gap: '5%',
        borderRadius: '10px',
        boxShadow: '0 0 0.938rem 0 rgba(0, 0, 0, 0.2)',
        cursor: "pointer",
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        "&:hover": {
            transform: 'scale(1.02)',
            boxShadow: '0 0 1.25rem 0 rgba(0, 0, 0, 0.4)',
        },
        "&:active": {
            transform: 'scale(0.98)',
            boxShadow: '0 0 0.625rem 0 rgba(0, 0, 0, 0.3)',
        }
    },

    cardIconSection: {
        // border: '2px solid blue',
    },

    cardInfoSection: {
        // border: '2px solid red',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },

    cardTitle: {
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: "'Poppins', sans-serif",
    },

    cardCount: {
        fontSize: '24px',
        fontWeight: '600',
        fontFamily: "'Poppins', sans-serif",
    },

    cardIcon: {
        fontSize: '50px',
        color: 'rgb(225,140,168);'
    }

}

const DashboardCard = ({ cardIcon: CardIcon, title, count, loading , navigate}) => {
    const Navigate= useNavigate();
    return (
        <Box sx={dashboardCardStyle.dashboardCardContainer} onClick={()=>Navigate(navigate)}>
            <Box sx={dashboardCardStyle.cardIconSection}>
                <CardIcon sx={dashboardCardStyle.cardIcon} />
            </Box>
            <Box  sx={dashboardCardStyle.cardInfoSection}>
                <Typography sx={dashboardCardStyle.cardTitle}>{title}</Typography>
                <Typography sx={dashboardCardStyle.cardCount}>{count}</Typography>
            </Box>
        </Box>
    );
};

export default DashboardCard;