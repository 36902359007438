import React, { useContext, useEffect, useRef, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import { saveAs } from "file-saver";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import { AuthContext } from "../../../context/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";

import BlockIcon from "@mui/icons-material/Block";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import BlockModal from "../../../Modal/BlockModal";
import toast from "react-hot-toast";
import typographyStyles from "../../../theme/Typography";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "stage", label: "Stage" },
  { id: "email_id", label: "User Email ID" },
  { id: "topic", label: "Topic Title" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const ForumListing = () => {
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(Number.MAX_SAFE_INTEGER);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [toggle, setToggle] = useState(false);
  const [noData, setNoData] = useState(false);

  const Navigate = useNavigate();
  const getForums = async () => {
    if (isAuthenticated && user) {
      try {
        const params = {
          page: 1,
          limit: rowsPerPage,
        };
        console.log(params);
        const res = await getDataHandlerWithToken("listForums", params, {});
        if (res.responseCode == 200) {
          console.log(res);
          setData(res.result.docs);
          console.log(res.result.docs);

          setFilteredData(res.result.docs); // Initially, all data is filtered data

          setLoading(false);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } catch (error) {
        console.log("Error while fetching forums : ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getForums();
  }, [isAuthenticated, user, rowsPerPage]);

  const handleBlockConfirm = async () => {
    let forumId = blockId;
    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockForum",
        {},
        { forumId }
      );
      if (res.responseCode === 200) {
        setToggle((prevToggle) => !prevToggle);
        setBlockModalOpen(false);
        console.log("blocked");
        toast.success(res.responseMessage);
        getForums();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleView = (id) => {
    // Handle block action
    console.log("Block button clicked for row id:", id);
  };

  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = data;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((forum) =>
        forum.topicTitle
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((forum) => {
        const createdAtDate = new Date(forum.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((forum) => {
        const createdAtDate = new Date(forum.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (forum) => forum.userId?.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };

  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = filteredData.map(({ _id, ...rest }) => rest);
    const headers = ["Sr.No.", "Stage", "Email", "Topic title","Created Date & Time"];
    const dataToExport = dataWithoutId.map((row, index) => ({
      sr_no: index + 1,
      
      stage: row?.userId?.stage,
      email_id: row?.userId?.email,
      topic:row?.topicTitle,
     

      
      created_date_time: new Date(row.createdAt).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
    
    }));

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";
  // Append rows of data
  csvContent += dataToExport
  .map((row) =>
    columns
      .map((col) => {
        const value = row[col.id];
        return typeof value === "string" && value.includes(",")
          ? `"${value}"` // Escape commas in CSV values
          : value;
      })
      .join(",")
  )
  .join("\n");


    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `forum_list_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };
  return (
    <DashboardLayout
      sectionName="Forum Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Forum Managment
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              {" "}
              Forum Managment
            </Typography>
          </Box>

          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            onFilterChange={handleFilterChange}
            placeholder="Enter topic title"
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length <1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((row, index) => ({
                sr_no: index + 1,
                stage: (
                  <Tooltip
                    title={
                      se.find((option) => option.value === row.userId?.stage)
                        ?.key || "Unknown Stage"
                    }
                    arrow
                  >
                    <span>
                      {(
                        se.find((option) => option.value === row.userId?.stage)
                          ?.key || "Unknown Stage"
                      ).length > 20
                        ? `${(
                            se.find(
                              (option) => option.value === row.userId?.stage
                            )?.key || "Unknown Stage"
                          ).substring(0, 20)}...`
                        : se.find(
                            (option) => option.value === row.userId?.stage
                          )?.key || "Unknown Stage"}
                    </span>
                  </Tooltip>
                ),
                email_id: row.userId?.email,
                topic: row?.topicTitle,
                created_date_time: new Date(row?.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                ),
                action: (
                  <Box className="row-button-container">
                    <VisibilityIcon
                      variant="outlined"
                      onClick={() => {
                        Navigate(`viewforum/${row._id}`);
                      }}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        cursor: "pointer",
                      }}
                    />
                    <BlockIcon
                      onClick={() =>
                        handleBlock(row?._id, row?.topicTitle, row?.status)
                      }
                      sx={{
                        color: row.status === "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="FORUM"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default ForumListing;
