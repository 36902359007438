import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Divider,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
} from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";
import {
  //   DndProvider,
  DragDropContext,
  Draggable,
  Droppable,
} from "@hello-pangea/dnd";
import { DragHandle } from "@mui/icons-material";

const columns = [
  "Sr.No.",
  "Recipe Name",
  "Recipe Type",
  "Created Date & Time",
  "Status",
  "Action",
];
const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(225,140,168)",
    color: "white",
  },
  cellStyle: {
    textAlign: "left",
    // fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
    height: "49px",
    fontSize: "0.875rem",
  },

  tableStyle: {
    textAlign: "left",
    color: "white",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
  },
};

const RecipeListing = () => {
  const Navigate = useNavigate();
  const [recipeList, setRecipeList] = useState([]);
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const rowsPerPageOptions = [10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const shouldShowPagination = recipeList?.length > rowsPerPage;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 12));
    setPage(0);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("getrecipeList", query);
      if (res.responseCode === 200) {
        setRecipeList(res?.result?.docs || []);
        setFilteredData(res?.result?.docs);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching recipe list:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Reorder only the current page data (filteredData), not the entire dataset
    const currentPageData = [...filteredData];
    const [movedRow] = currentPageData.splice(source.index, 1);
    currentPageData.splice(destination.index, 0, movedRow);

    // Update the filteredData with the reordered rows
    setFilteredData(currentPageData);
    setRecipeList(currentPageData);

    // Prepare the reordered data to be sent to the database, ensuring global indices are updated
    const updatedOrderArray = currentPageData.reverse().map((item, index) => ({
      recipeId: item._id,
      orderId: (index + 1).toString(),
    }));

    console.log("Reordered data to update:", updatedOrderArray);

    // Persist the new order to the database
    saveOrderToDB(updatedOrderArray);
  };

  const saveOrderToDB = async (updatedOrderArray) => {
    try {
      const data = { order: updatedOrderArray };

      const res = await putDataHandlerWithToken("updateRecipesOrder", data);
      if (res?.responseCode === 200) {
        toast.success("Order updated successfully!");
        fetchData();
      } else {
        toast.error("Failed to update order");
      }
    } catch (error) {
      console.error("Error saving order to the database:", error);
      toast.error("An error occurred while updating order");
    }
  };

  useEffect(() => {
    fetchData();
  }, [render]);

  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    try {
      const query = { recipeId: id };
      const res = await putDataHandlerWithToken("blockrecipe", {}, query);
      if (res.responseCode === 200) {
        // Update recipeList with the updated status
        const updatedRecipeList = recipeList.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setRecipeList(updatedRecipeList);
        setFilteredData(updatedRecipeList); // Trigger re-render if necessary (depending on your use case)
        setBlockModalOpen(false);
        toast.success("Recipe status updated successfully");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error blocking recipe:", error);
    }
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleView = (id) => {
    // Handle view action
    console.log("Block button clicked for row id:", id);
    Navigate(`/recipelisting/viewrecipe`, { state: { recipeId: id } });
  };

  const handleEdit = (id) => {
    // Handle edit action
    Navigate(`/recipelisting/editrecipe`, { state: { recipeId: id } });
  };
  const handleFilterChange = (filterCriteria) => {
    let filtered = recipeList; // Start with the full list of recipes
  
    console.log(filterCriteria);
  
    // 1. Apply searchText filter (if provided)
    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.recipeName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
    }
  
    // 2. Apply recipeType filter (if provided)
    if (filterCriteria.recipeType) {
      filtered = filtered.filter((category) =>
        category.recipeType.some((type) =>
          type.recipeType
            .toLowerCase()
            .includes(filterCriteria.recipeType.toLowerCase().trim())
        )
      );
    }
  
    // 3. Date filter helper function
    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };
  
    // 4. Apply fromDate filter (if provided)
    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");
  
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });
  
      console.log("Filtered categories (after fromDate):", filtered);
    }
  
    // 5. Apply toDate filter (if provided)
    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);
  
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });
  
      console.log("Filtered categories (after toDate):", filtered);
    }
  
    // 6. Apply stage filter (if provided and not "All")
    if (filterCriteria.selectedValue && filterCriteria.selectedValue !== "All") {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter((category) =>
        category.stage.includes(filterCriteria.selectedValue)
      );
    }
  
    // 7. Set the filtered data to state
    setFilteredData(filtered);
    console.log("Final filtered data:", filtered);
  };
  
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = recipeList?.map(({ _id, ...rest }) => rest);
    const headers = ["Sr.No.", "Recipe Name", "Created Date & Time", "Status"];

    const dataToExport = dataWithoutId.map((row, index) => ({
      sr_no: index + 1,

      recipe_name: row.recipeName,

      created_date_time: new Date(row.createdAt).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      status: row.status,
    }));

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataToExport
      .map((row) =>
        columns
          .map((col) => {
            const value = row[col.id];
            return typeof value === "string" && value.includes(",")
              ? `"${value}"` // Escape commas in CSV values
              : value;
          })
          .join(",")
      )
      .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `RecipeListing_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };
  console.log(filteredData);
  return (
    <DashboardLayout
      sectionName="Content - Recipe Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Recipe Listing
            </Typography>
          </Box>

          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Recipe"
                onClick={() => Navigate("/recipelisting/addrecipe")}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Enter recipe name"
            onFilterChange={handleFilterChange}
            showRecipeTypeDropdown={true}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "63vh", overflowY: "auto" }}
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  {filteredData?.length === 0 ? (
                    <DataNotFound />
                  ) : (
                    <Table sx={{ minWidth: 800 }}>
                      <TableHead stickyHeader>
                        <TableRow sx={PaginationTableStyle.rowStyle}>
                          {columns.map((e, i) => (
                            <TableCell
                              key={i}
                              align="left"
                              sx={PaginationTableStyle.tableStyle}
                            >
                              {e}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="programList">
                          {(provided) => (
                            <TableBody
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {filteredData?.map((item, i) => {
                                // Define stageKeys and stageText here, outside of JSX
                                // const stageKeys = se
                                //   .filter((option) =>
                                //     item.stage.includes(option.value)
                                //   )
                                //   .map((option) => option.key);
                                // const stageText =
                                //   stageKeys.join(", ") || "Unknown Stage";
                                console.log(item);
                                return (
                                  <Draggable
                                    key={item?.id}
                                    draggableId={item?.id}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#faebf0"
                                            : "inherit",
                                          ...provided.draggableProps.style,
                                          width: "100%",
                                        }}
                                        sx={PaginationTableStyle.rowStyle}
                                        key={i}
                                      >
                                        <TableCell
                                          scope="row"
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {currentPage
                                            ? (currentPage - 1) * 10 + (i + 1)
                                            : i + 1}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.recipeName
                                            ? item?.recipeName
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.recipeType?.length > 0
                                            ? item.recipeType.map(
                                                (type, index) => (
                                                  <span key={index}>
                                                    {type.recipeType}
                                                    {index <
                                                      item.recipeType.length -
                                                        1 && ", "}{" "}
                                                    {/* Add a comma between values */}
                                                  </span>
                                                )
                                              )
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.createdAt
                                            ? new Date(
                                                item?.createdAt
                                              ).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              })
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.status ? item?.status : "NA"}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          <VisibilityIcon
                                            variant="outlined"
                                            onClick={() => handleView(item._id)}
                                            sx={{
                                              color: "rgb(225,140,168)",
                                              borderColor: "rgb(225,140,168)",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <EditIcon
                                            onClick={() => handleEdit(item._id)}
                                            sx={{
                                              color: "rgb(225,140,168)",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily:
                                                "'Poppins', sans-serif",
                                              cursor: "pointer",
                                              ml: 2,
                                              mr: 2,
                                            }}
                                          />
                                          <BlockIcon
                                            onClick={() =>
                                              handleBlock(
                                                item._id,
                                                item.workoutCategoryName,
                                                item.status
                                              )
                                            }
                                            sx={{
                                              color:
                                                item.status === "ACTIVE"
                                                  ? "green"
                                                  : "red",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily:
                                                "'Poppins', sans-serif",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <DragHandle sx={{ ml: 4 }} />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </TableBody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                  )}
                </>
              )}

              {/* {shouldShowPagination && (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={filteredData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    "& .MuiTablePagination-selectIcon": {
                      color: "rgb(225,140,168)",
                    },
                  }}
                />
              )} */}
            </TableContainer>
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="RECIPE"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default RecipeListing;
