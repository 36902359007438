export const modalStyles = {
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    maxWidth: "600px",
    maxHeight: "600px",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  cropperContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  cropButton: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
  cancelButton: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    padding: "10px 20px",
    backgroundColor: "#6c757d",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

export const commonGridStyle = {
  // Light border for separation
  border: "1px solid #ddd",
  // Rounded corners for visual softness
  borderRadius: "8px",
  // Padding inside each box for spacing
  padding: "10px",
  // Spacing between boxes
  marginBottom: "25px",
  // Light background color to distinguish each item
  backgroundColor: "#f9f9f9",

};
