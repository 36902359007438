import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  CodeBlock,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  SelectAll,
  Style,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
  addEditInfo: {
    fontWeight: "normal",
    wordBreak: "break-word", // Ensure text wraps within the cell
    overflow: "hidden", // Hide overflow content
    textOverflow: "ellipsis", // Show ellipsis (...) for overflowing text
  },
};

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const ViewActivation = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnail2, setThumbnail2] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getDataHandlerWithToken(`getActivateExercise`, {
          id,
        });
        if (res.responseCode === 200) {
          const data = res.result;
          setSelectedValue(
            se.find((option) => option.value === data?.stage).key
          );
          setEditorContent(data?.learn);
          setThumbnail(data?.video1);
          setThumbnail2(data?.video2);
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        console.error("Failed to fetch data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="View Activation"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            View Activation
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Stage:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {selectedValue}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Learn how to activate:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <div
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Activate GIF set(1):
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Card
                          sx={{
                            maxWidth: 250,
                            maxHeight: "auto",
                            margin: "5px",
                          }}
                        >
                          <CardMedia
                            component="video"
                            src={thumbnail}
                            controls
                          />
                        </Card>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Activate GIF set(2):
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Card
                          sx={{
                            maxWidth: 250,
                            maxHeight: "auto",
                            margin: "5px",
                          }}
                        >
                          <CardMedia
                            component="video"
                            src={thumbnail2}
                            controls
                          />
                        </Card>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {/* {program?.description && (
                    <TableRow>
                      <TableCell sx={addEditStyle.addEditLabel}>
                        Description:
                      </TableCell>
                      <TableCell sx={addEditStyle.addEditInfo}>
                        {stripHtml(program?.description)}
                      </TableCell>
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewActivation;
