import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import AlertDialog from "../../../components/AlertDialog";
import "../../../scss/main.css";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishIcon from "@mui/icons-material/Unpublished";
import {
  deleteDataHandler,
  getDataHandlerWithToken,
  postDataHandler,
  postDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import moment from "moment";
import FilterBox from "../../../components/FilterBox";
import DataNotFound from "../../../components/DataNotFound";
import DeleteModal from "../../../Modal/DeleteModal";
import Loading from "../../../components/Loading";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "subject", label: "Subject" },
  { id: "created_at", label: "Created Date & Time" },
  { id: "customer_name", label: "Customer Name" }, // Replaced rating with publish
  { id: "customer_email", label: "Customer Email" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const TicketListing = () => {
  const Navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [ticketData, setTicketData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const response = await getDataHandlerWithToken("listContactUs");


      const sortedData = (response?.result  || []).sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      if (response?.responseCode === 200) {
        setTicketData(sortedData);
        setFilteredData(sortedData);
      }
    
    } catch (error) {
      console.error("Error in fetching data.", error);
     
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    console.log(filteredData);
  }, []);

  const handleEdit = (testimonialId) => {
    Navigate(
      `/staticcontent/testimonialmanagement/edittestimonial/${testimonialId}`
    );
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = ticketData;
    console.log(filtered);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.fullName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim()) ||
          (category.email &&
            category.email.toLowerCase().includes(filterCriteria.searchText.toLowerCase().trim())) ||
            (category.email &&
              category.email.toLowerCase().includes(filterCriteria.searchText.toLowerCase().trim()))
      );
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      // Additional filtering logic if needed
    }

    setFilteredData(filtered);
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Ticket Management"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
         
          <Typography sx={{ ...typographyStyles.h4 }}>
            Ticket Management
          </Typography>
        </Box>
      </Box>
      <br />
     
      <Divider /> 
      <br/>
      <Box className="filter-container">
        <FilterBox
          placeholder="Enter customer name/email"
          onFilterChange={handleFilterChange}
          dropdownNone={"none"}
        />
      </Box>
      <Box className="StaticScreenContainer">
        <Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
                width: "100%",
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData?.map((item, index) => ({
                sr_no: index + 1,
                customer_name: item?.fullName,
                subject: item?.message,
                customer_email: item?.email,
                created_at: moment(item.createdAt).format("MM/DD/YYYY, h:mm A"),
                status: item?.status,
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <Tooltip title="View">
                      <VisibilityIcon
                        onClick={() =>
                          Navigate(`/ticketListing/viewTicket`, {
                            state: { contactUs: item },
                          })
                        }
                        sx={{
                          color: "rgb(225,140,168)",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default TicketListing;
