import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography, Divider } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import AlertDialog from "../../../components/AlertDialog"; // Adjust the path as needed
import "../../../scss/main.css";
import {
  listBanner,
  deleteDataHandler,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import DataNotFound from "../../../components/DataNotFound";
import BlockModal from "../../../Modal/BlockModal";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "banner_title", label: "Banner Title" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const BannerManagement = () => {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [bannerData, setBannerData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    // Fetch data when component mounts
    listBanner("getAllBanners")
      .then((response) => {
        if (response?.responseCode === 200) {
          setBannerData(response.result.docs); // Assuming response.data.data contains the list of FAQs
        } else {
          toast.error(
            response?.responseMessage || "Failed to fetch banner data"
          );
        }
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        // toast.error("Failed to fetch banner data");
      });
  }, [toggle]); // Empty dependency array ensures this effect runs only once

  const handleEdit = (bannerId) => {
    Navigate(`/staticcontent/bannermanagement/editbanner/${bannerId}`);
  };

  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };
  const handleBlockConfirm = async () => {
    let bannerId = blockId;
    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockBanner",
        {},
        { bannerId }
      );
      if (res.responseCode === 200) {
        setToggle((prevToggle) => !prevToggle);
        setBlockModalOpen(false);

        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDelete = async (bannerId) => {
 

    deleteDataHandler("deleteBanner", { bannerId: bannerId })
      .then((response) => {
        if (response?.responseCode === 200) {
          // Assuming response.data.data contains the list of FAQs
          toast.success("Banner deleted successfully");
          setBannerData(bannerData.filter((item) => item._id !== bannerId));
        } else {
          toast.error(response?.responseMessage || "Failed to delete banner ");
        }
      })
      .catch((error) => {
        console.error("Error deleting banner", error);
        toast.error("Failed to delete banner ");
      });

    setOpen(false);
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Banner Management"
    >
      <Box className="heading-container-top">
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{
                  // // width: "2.813",
                  // height: "5.813",
                  cursor: "pointer",
                }}
                onClick={() => Navigate("/staticcontent")}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>
              Banner Management
            </Typography>
          </Box>
        </Box>

        <Box className="button-container">
          <BasicButtons
            text="+Add Banner"
            onClick={() => {
              Navigate("/staticcontent/bannermanagement/addbanner");
            }}
          />
        </Box>
      </Box>
      <br></br>
      <Divider />
      <Box className="StaticScreenContainer">
        <Box>
          {bannerData?.length === 0 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={bannerData?.map((item, index) => ({
                sr_no: index + 1,
                banner_title: item.bannerName,
                created_date_time: item.createdAt,
                action: (
                  <Box className="row-button-container">
                 
                    
                    <DeleteIcon
                      variant="outlined"
                      onClick={() => handleClickOpen(item)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />

                    <EditIcon
                    onClick={() => handleEdit(item._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />

                   

                    <BlockIcon
                      onClick={() =>
                        handleBlock(item._id, item.bannerName, item.status)
                      }
                      sx={{
                        color: item.status == "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleDelete={() => handleDelete(selectedRow?._id)}
        title="Delete Item?"
        description={`Are you sure you want to delete the banner titled "${selectedRow?.bannerName}"? This action cannot be undone.`}
      />

      {blockModalOpen && (
        <BlockModal
          title="BANNER"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default BannerManagement;
