import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography,Divider } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { addExerciseSchema } from "../../../schema";
import "../../../scss/main.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	CodeBlock,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	GeneralHtmlSupport,
	Heading,
	HorizontalLine,
	Indent,
	IndentBlock,
	Italic,
	Link,
	Paragraph,
	SelectAll,
	Style,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	Underline,
	Undo
} from 'ckeditor5';
import {
  postDataHandler,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";


const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const AddExercise = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { workoutCategoryId } = useParams();
  const navigate = useNavigate();

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    setThumbnail(file);
    formik.setFieldValue("categoryImage", file);
  };

  const formik = useFormik({
    initialValues: {
      exerciseName: "",
      categoryImage: null,
      description: "",
    },
    validationSchema: addExerciseSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const formData = new FormData();
        formData.append("file", values.categoryImage);
        const result = await postDataHandler("uploadFile", formData);

        const res = await postDataHandlerWithToken("addUpdateExercise", {
          categoryId: workoutCategoryId,
          exerciseName: values.exerciseName,
          description: values.description,
          image: [result.result],
        });

        if (res.responseCode === 200) {
          navigate(-1)
          toast.success("Data added");
          
        } else {
          toast.error("Error while adding data");
        }
      } catch (error) {
        console.error("Error while adding exercise:", error);
      }finally{
        setLoading(false)
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  return (
    <DashboardLayout
      sectionName="Workout Management - Category - Exercise"
      title="Add Exercise"
      showBackButtonSection={true}
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap:"10px"
          }}
        >
           <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
        Add Exercise
          </Typography>

         
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Exercise Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="exerciseName"
                  value={values.exerciseName}
                  maxLength={30}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter exercise name"
                />
                {errors.exerciseName && touched.exerciseName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.exerciseName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Exercise Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  onBlur={handleBlur}
                  onChange={handleThumbnailChange}
                  inputProps={{ accept: "image/*" }}
                />
                {errors.categoryImage && touched.categoryImage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.categoryImage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container desc-box">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <CKEditor
                  editor={ClassicEditor}
                  data={values.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("description", data);
                  }}
                  onBlur={() => formik.setFieldTouched("description", true)}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                      "|",
                      "link",
                      "imageUpload",
                      "insertTable",
                      "mediaEmbed",
                      "|",
                      "undo",
                      "redo",
                    ],
                    height: "400px",
                  }}
                  className="StaticEditor"
                />
                {errors.description && touched.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.description}
                  </p>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box className="add-edit-button-section" sx={{ alignItems: "center", justifyContent: "center" }}>
            <BasicButtons text={"Add Exercise" } disabled={loading} name="submit" type="submit" loading={loading} width={"20%"} />
            {/* <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => {
                navigate("/workoutlisting");
              }}
            /> */}
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddExercise;
