import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Card,
  CardMedia,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  CodeBlock,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  SelectAll,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  List,
  ListProperties,
  Underline,
  Undo,
} from "ckeditor5";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import EditIcon from "@mui/icons-material/Edit";
import { commonGridStyle } from "../../../scss/style";
import { workoutSubcategory } from "../../../schema";
import Loading from "../../../components/Loading";
import ImageCropper from "../../../utils/ImageCropper";
import {VIDEO_ENC} from "../../../data/sidebarData"
import RemoveIcon from "@mui/icons-material/Remove";


const editorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "style",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "bulletedList",
      "numberedList",

      "|",
      "horizontalLine",
      "link",
      "insertTable",
      "blockQuote",
      "codeBlock",
      "|",
      "alignment",
      "|",
      "outdent",
      "indent",
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    Indent,
    IndentBlock,
    Italic,
    Link,
    Paragraph,
    SelectAll,
    List,
    ListProperties,

    TextTransformation,
    Underline,
    Undo,
  ],
  fontFamily: {
    supportAllValues: true,
  },
  fontSize: {
    options: [10, 12, 14, "default", 18, 20, 22],
    supportAllValues: true,
  },
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true,
      },
    ],
  },

  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: "https://",
    decorators: {
      toggleDownloadable: {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "file",
        },
      },
    },
  },
  placeholder: "Type or paste your content here!",
};

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addRemoveButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "400",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
    marginLeft: "2px",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(225,140,168)",
    },
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditSubcategory = () => {
  const [selectedValue, setSelectedValue] = useState("Select Stage");
  const [categoryId, setCategoryId] = useState();
  const [loading, setLoading] = useState(false);
  const [uploadedFileURLs, setUploadedFileURLs] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [imagePreviews, setImagePreviews] = useState([]);

  const [loadingImage, setLoadingImage] = useState(false);

  const [editorContent, setEditorContent] = useState("");
  const [subcategory, setSubcategory] = useState();
  const [exerciseEditorContent, setExerciseEditorContent] = useState("");
  const [exerciseSections, setExerciseSections] = useState([
    {
      description: "",
      exercises: [],
    },
  ]);



  const [croppedImage, setCroppedImage] = useState(null);


  const navigate = useNavigate();
  const location = useLocation();
  const [editorVisibility, setEditorVisibility] = useState([]);

  // Toggle editor visibility
  const handleToggleEditor = (contentIndex) => {
    if (editorVisibility[contentIndex]) {
      // Clear the editor content when closing it
      setExerciseSections((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[contentIndex].description = ""; // Clear description or data field
        return updatedDetails;
      });
    }

    setEditorVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[contentIndex] = !prevVisibility[contentIndex]; // Toggle visibility at the index
      return updatedVisibility;
    });
  };

  useEffect(() => {
    // Initialize the editor visibility based on whether the description exists
    const visibilityArray = exerciseSections.map(
      (content) => !!content.description
    ); // true if description exists, false otherwise
    setEditorVisibility(visibilityArray);
  }, [exerciseSections]);

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    setCategoryId("");
  };
  const fetchCuisine = async () => {
    const query = {
      subcategoryId: location.state.subcategoryId,
    };
    try {
      setLoading(true)
      const res = await getDataHandlerWithToken(
        "viewWorkoutSubCategory",
        query
      );
      if (res.responseCode === 200) {
        setSubcategory(res?.result);
        setEditorContent(res?.result?.description);
        setExerciseEditorContent(res?.result?.exercisesInstructions || "--");
        setUploadedFileURLs(res?.result?.image);

          // Function to remove the security string from videoKey
      const removeSecurityFromVideoKey = (videoKey) => {
        
        if (videoKey && videoKey.endsWith(`${VIDEO_ENC}`)) {
          return videoKey.replace(`${VIDEO_ENC}`, ""); // Remove the security string
        }
        return videoKey; // Return as-is if no security string found
      };

      const sections = res?.result?.exerciseSections?.map(({ _id, ...rest }) => ({
        ...rest,
        exercises: rest.exercises?.map(({ _id, ...exercise }) => ({
          ...exercise,
          videoKey: removeSecurityFromVideoKey(exercise.videoKey), // Remove security string from videoKey
        })),
      }));

      setExerciseSections(sections);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)

    }
  };
  useEffect(() => {
    fetchCuisine();
  }, []);
  
  const validationSchema = workoutSubcategory;

  const handleFileSelect = async (event) => {
    const file = event.currentTarget.files[0];

    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      
      formik.setFieldValue("image", [...formik.values.image, file]);
    }
   
  };

  const handleCropComplete = (cloudinaryUrl) => {
    setCroppedImage(cloudinaryUrl);
    setUploadedFileURLs([cloudinaryUrl])
    setImagePreviews([...imagePreviews, cloudinaryUrl]);
    setSelectedImage(null);
  };
  const handleRemoveStep = (index, dataType, setDataType, field) => {
    const newData = [...dataType];
    newData.splice(index, 1);
    setDataType(newData);
    if (field.length > 0) {
      formik.setFieldValue(field, [...newData]);
    }

    //console.log(uploadedFileURLs);
  };

  const handleAddSection = () => {
    setExerciseSections((prevSections) => [
      ...prevSections,
      {
        description: "", // New section description
        exercises: [
          
        ], // One empty exercise to start with
      },
    ]);
  };

  const handleRemoveSection = (indexToRemove) => {
    setExerciseSections((prevSections) =>
      prevSections.filter((section, index) => index !== indexToRemove)
    );
  };
  const handleAddExercise = (sectionIndex) => {
    setExerciseSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].exercises.push({
        title: "",
        video: "",
        videoKey: "",
      });
      return updatedSections;
    });
  };
  const handleExerciseChange = (sectionIndex, exerciseIndex, field, value) => {
    setExerciseSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].exercises[exerciseIndex][field] = value;
      return updatedSections;
    });
  };

  const handleRemoveExercise = (sectionIndex, exerciseIndex) => {
    setExerciseSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].exercises.splice(exerciseIndex, 1);
      return updatedSections;
    });
  };

  const handleSectionChange = (sectionIndex, field, value) => {
    const updatedSections = [...exerciseSections];
    updatedSections[sectionIndex][field] = value;
    setExerciseSections(updatedSections); // Update the state with the new sections
    formik.setFieldValue("exerciseSections", updatedSections); // Also update Formik field value if necessary
  };

  const handleFormSubmit = async (values) => {
    console.log("hhhh");
    const data = {
      workoutSubCategoryId: subcategory._id,
      stage: se.find((option) => option.key == values.selectStage).value,
      categoryId: subcategory.categoryId,
      workoutSubCategoryName: values.workoutSubCategoryName,
      image: uploadedFileURLs,
      exerciseSections: exerciseSections,
      description: values.description,
    };
    console.log(values.exercises);

    try {
      setLoading(true);
      const res = await postDataHandlerWithToken(
        "addUpdateWorkoutSubCategory",
        data
      );
      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        navigate(-1);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectStage: subcategory?.stage
        ? se.find((option) => option.value == subcategory.stage).key
        : "",
      workoutSubCategoryName: subcategory?.workoutSubCategoryName || "",
      image: subcategory?.image,

      exerciseSections: exerciseSections || [],

      description: subcategory?.description || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh'  // Takes the full viewport height
      }}>
        <Loading />
      </div>
    );
  }
  

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Add Program"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Edit Workout Subsection
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="selectStage"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={formik.values.selectStage}
                  placeholder="Select stage"
                  onChange={(value) => {
                    formik.setFieldValue("selectStage", value);
                    handleDropdownChange(value);
                  }}
                />
                {formik.errors.selectStage && formik.touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Subsection Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="workoutSubCategoryName"
                  value={formik.values.workoutSubCategoryName}
                  maxLength={30}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Subsection name"
                />
                {formik.errors.workoutSubCategoryName &&
                formik.touched.workoutSubCategoryName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.workoutSubCategoryName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box className="add-edit-input-field ckeditor-container">
                <CKEditor
                  editor={ClassicEditor}
                  data={editorContent}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorContent(data);
                    formik.setFieldValue("description", data);
                  }}
                  config={editorConfig}
                />
                {formik.errors.description && formik.touched.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.description}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Banner Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Button
                  sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                  variant="contained"
                  component="label"
                  startIcon={<AddIcon />}
                  disabled={uploadedFileURLs.length === 1}
                >
                  Image{" "}
                  {loadingImage && (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleFileSelect}
                  />
                </Button>

                <Grid container spacing={2} sx={{ mt: "10px" }}>
                  {uploadedFileURLs.map((preview, index) => (
                    <Grid item xs={3} key={index}>
                      <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={preview}
                          alt={`Preview ${index}`}
                        />
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleRemoveStep(
                              index,
                              uploadedFileURLs,
                              setUploadedFileURLs,
                              "image"
                            );
                          }}
                        >
                          <DeleteIcon sx={{ color: "rgb(225, 140, 168)" }} />
                        </IconButton>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                {formik.errors.image && formik.touched.image ? (
                  <p style={{ color: "red", float: "left" }}>
                    {formik.errors.image}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container" sx={{ width: "100%" }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Exercise Sections:
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                {exerciseSections.map((section, sectionIndex) => (
                  <Box key={sectionIndex} mb={4}>
                    {/* Section description */}
                    <Grid container alignItems="center" sx={commonGridStyle}>

                    <Box className="add-edit-input-field">
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            textTransform: "none",
                        
                           
                            minWidth:"30%"
                          }}
                          variant="contained"
                          onClick={() => handleToggleEditor(sectionIndex)}
                          startIcon={
                            editorVisibility[sectionIndex] ? (
                              <RemoveIcon />
                            ) : (
                              <AddIcon />
                            )
                          }
                        >
                          Add Description
                        </Button>

                        <Grid className="ckeditor-container" mb={1} mt={1} >
                           {editorVisibility[sectionIndex] && (
                          <CKEditor
                         
                            editor={ClassicEditor}
                            name={`description`}
                            data={section.description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              handleSectionChange(
                                sectionIndex,
                                "description",
                                data
                              );
                            }}
                            config={editorConfig}
                            className="StaticEditor"
                          />
                        )}
                        </Grid>

                       
                      </Box>
                      <Box className="add-edit-input-field">
                        {section.exercises.map((exercise, exerciseIndex) => (
                          <Box key={exerciseIndex} mb={2} >
                            <Grid
                              container
                              alignItems="center"
                              sx={commonGridStyle}
                            >
                              {/* Exercise Title */}
                              <Grid item xs={12}>
                                <InputField
                                  placeholder={`Exercise title  ${
                                    exerciseIndex + 1
                                  }`}
                                  value={exercise.title}
                                  maxLength={200}
                                  onChange={(e) =>
                                    handleExerciseChange(
                                      sectionIndex,
                                      exerciseIndex,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} mt={2}>
                                <InputField
                                  placeholder={`Video script ${
                                    exerciseIndex + 1
                                  }`}
                                  value={exercise.video}
                                  onChange={(e) =>
                                    handleExerciseChange(
                                      sectionIndex,
                                      exerciseIndex,
                                      "video",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} mt={2}>
                                <InputField
                                  placeholder={`Video hls link ${
                                    exerciseIndex + 1
                                  }`}
                                  value={exercise.videoKey}
                                  onChange={(e) =>
                                    handleExerciseChange(
                                      sectionIndex,
                                      exerciseIndex,
                                      "videoKey",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>

                              {/* Add/Remove buttons for exercises */}
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    mt: "5px",
                                  }}
                                >
                                  {section.exercises.length > 0 && (
                                    <IconButton
                                      sx={{ ...addEditStyle.addRemoveButton }}
                                      onClick={() =>
                                        handleRemoveExercise(
                                          sectionIndex,
                                          exerciseIndex
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            textTransform: "none",
                            minWidth:"30%"
                          }}
                          variant="contained"
                          component="label"
                          onClick={() => handleAddExercise(sectionIndex)}
                          startIcon={<AddIcon />}
                        >
                          Add Videos
                        </Button>
                      </Box>

                     

                      {/* Add/Remove section */}
                      <Grid item xs={12}>
                        <Box sx={{ display: "flex", gap: "10px", mt: "5px" }}>
                          {sectionIndex === exerciseSections.length - 1 && (
                            <IconButton
                              sx={{ ...addEditStyle.addRemoveButton }}
                              onClick={() => handleAddSection()}
                            >
                              <AddCircleOutlineRoundedIcon />
                            </IconButton>
                          )}

                          {exerciseSections.length > 1 && (
                            <IconButton
                              sx={{ ...addEditStyle.addRemoveButton }}
                              onClick={() => handleRemoveSection(sectionIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {formik.errors.exerciseSections &&
                formik.touched.exerciseSections ? (
                  <p style={{ color: "red" }}>
                    {formik.errors.exerciseSections}
                  </p>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => navigate(-1)}
            />
            <BasicButtons
              text={"Edit Subsection"}
              name="submit"
              type="submit"
              width={"25%"}
              disabled={loading}
              loading={loading}
            />
          </Box>
        </form>
      </Box>

      <ImageCropper
        image={selectedImage}
        aspectRatio={16/9}
        onCropComplete={handleCropComplete}
        onClose={() => setSelectedImage(null)}
      />
    </DashboardLayout>
  );
};

export default EditSubcategory;
