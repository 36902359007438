import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import AdminImg from "../../../assets/profileimg-h2m.png"; // Default image

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
  },
};

const stage = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const getStageKey = (value) => {
  const stageItem = stage.find(item => item.value === value);
  return stageItem ? stageItem.key : value;
};

const ViewMum = () => {
  const [mum, setMum] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchMum = async () => {
    const query = {
      userId: location.state?.userId,
    };
    try {
      const res = await getDataHandlerWithToken("viewMum", query);
      if (res.responseCode === 200) {
        setMum(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMum();
  }, []);

  return (
    <DashboardLayout
      sectionName="Mum Management"
      title="User Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View User</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>User Name:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {mum?.fullName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Profile Image:
                    </TableCell>
                    <TableCell>
                      <Card sx={{ maxWidth: 250, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={mum?.profilePicture || AdminImg}
                          alt="Profile Picture"
                        />
                      </Card>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Phone Number:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {mum?.phoneNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Email Address:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {mum?.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Country:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {mum?.country}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Stage:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {getStageKey(mum?.stage)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Status:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {mum?.status}
                    </TableCell>
                  </TableRow>
                  {mum?.description && (
                    <TableRow>
                      <TableCell sx={addEditStyle.addEditLabel}>
                        Description:
                      </TableCell>
                      <TableCell sx={addEditStyle.addEditInfo}>
                        {stripHtml(mum?.description)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewMum;
