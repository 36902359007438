import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import ViewImg from "../../../assets/viewImg.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import Loading from "../../../components/Loading";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
};

const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const ViewForum = () => {
  const [forum, setForum] = useState(null);
  const navigate = useNavigate();
  const { forumId } = useParams();

  const fetchForum = async () => {
    try {
      const res = await getDataHandlerWithToken("viewForum", { forumId });
      if (res?.responseCode === 200) {
        setForum(res.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchForum();
  }, []);


  return (
    <DashboardLayout
      sectionName="Content - Forum Management"
      title="Forum Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Forum</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Owner:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Avatar
                          src={forum?.userId?.profilePicture}
                          alt="Profile Picture"
                        />
                        {forum?.userId?.fullName}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Topic Title:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {forum?.topicTitle}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Description:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {stripHtml(forum?.description)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Status:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {forum?.status}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              sx={{ ...addEditStyle.addEditTitle, marginTop: "20px" }}
            >
              Replies
            </Typography>
            <List>
              {forum?.userReply?.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No replies available" />
                </ListItem>
              ) : (
                forum?.userReply?.map((reply) => (
                  <ListItem key={reply._id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        src={reply?.userId?.profilePicture}
                        alt="Profile Picture"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={reply?.userId?.fullName}
                      secondary={
                        <>
                          <Typography
                            sx={{
                              display: "inline",
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {reply?.message}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {new Date(reply.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewForum;
