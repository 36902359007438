import { Box, Typography } from "@mui/material";
import React from "react";
import BasicButtons from "../components/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

const deleteModalStyle = {
  deleteContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 25%",
    background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    position: "fixed", // Ensure it covers the whole screen
    top: 0,
    left: 0,
    zIndex: 1000,
  },

  deleteContent: {
    padding: "30px",
    width: "100%",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    background: "white",
  },

  deleteTextSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },

  deleteButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "2%",
    width: "100%",
  },

  deleteIcon: {
    fontSize: "100px",
    color: "rgb(255, 87, 87)", // Red color for delete action
  },

  deleteTitle: {
    fontSize: "32px",
    fontWeight: "700",
    fontFamily: "'Poppins', sans-serif",
  },

  deleteText: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Poppins', sans-serif",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    textAlign: "center", 

  },
};

const DeleteModal = ({ handleClose, handleDelete, title, name }) => {
  const navigate = useNavigate();

  return (
    <Box sx={deleteModalStyle.deleteContainer}>
      <Box sx={deleteModalStyle.deleteContent}>
        <Box>
          <DeleteIcon sx={deleteModalStyle.deleteIcon} />
        </Box>
        <Box sx={deleteModalStyle.deleteTextSection}>
          <Typography sx={deleteModalStyle.deleteTitle}>
            Delete {title}
          </Typography>
          <Typography sx={deleteModalStyle.deleteText}>
            Are you sure you want to delete <span style={{
    fontWeight: "600", // Slightly lighter than 800 for balance
    color: "#333", // Darker grey for better readability
    fontFamily: "'Poppins', sans-serif", // Consistent font family
    
}}>
    {name}
</span>?
          </Typography>
        </Box>
        <Box sx={deleteModalStyle.deleteButtonSection}>
          <BasicButtons text="Yes" onClick={handleDelete} />
          <BasicButtons text="No" onClick={handleClose} />
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteModal;
