import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthGuard from "../components/AuthGuard";
import LoadingPage from "../views/pages/LoadingPage/LoadingPage";
import ViewForum from "../views/pages/ForumManagement/ViewForum";
import ListServiceAndSpecialization from "../views/pages/ServicesAndSpecialization/ListServiceAndSpecialization";
import AddServiceAndSpecialization from "../views/pages/ServicesAndSpecialization/AddServiceAndSpecialization";
import EditServiceAndSpecialization from "../views/pages/ServicesAndSpecialization/EditServiceAndSpecialization";
import ConsultsListing from "../views/pages/Consults/ConsultsListing";
import AddConsults from "../views/pages/Consults/AddConsults";
import SubscriptionListing from "../views/pages/Subscription/Subscription";
import AddSubscription from "../views/pages/Subscription/AddSubscription";
import EditSubscription from "../views/pages/Subscription/EditSubscription";
import ViewSubscription from "../views/pages/Subscription/ViewSubscription";
import MumListing from "../views/pages/Mum/MumListing";
import EditConsult from "../views/pages/Consults/EditConsults";
import ViewConsult from "../views/pages/Consults/ViewConsult";
import ViewMum from "../views/pages/Mum/ViewMum";
import ViewCoupon from "../views/pages/Coupon Management/ViewCoupon";
import BookingListing from "../views/pages/Booking/BookingListing.jsx";
import ViewBooking from "../views/pages/Booking/ViewBooking.jsx";
import ViewActivation from "../views/pages/ActivateManagement/ViewActivation";
import ActivateListing from "../views/pages/ActivateManagement/ActivateListing.jsx";
import AddActivation from "../views/pages/ActivateManagement/AddActivation.jsx";
import OptionListing from "../views/pages/OptionManagement/OptionListing.jsx";
import EditOption from "../views/pages/OptionManagement/EditOption.jsx";
import AddOption from "../views/pages/OptionManagement/AddOption.jsx";

// Import components directly instead of using lazy loading
import LogoutModal from "../Modal/LogoutModal";
import Login from "../views/pages/Auth/Login";
import ForgotPassword from "../views/pages/Auth/ForgotPassword";
import VerifyOTP from "../views/pages/Auth/VerifyOTP";
import ResetPassword from "../views/pages/Auth/ResetPassword";
import Dashboard from "../views/pages/Dashboard/Dashboard";
import Category from "../views/pages/Category/Category";
import AddCategory from "../views/pages/Category/AddCategory";
import EditCategory from "../views/pages/Category/EditCategory";
import ProgramListing from "../views/pages/Programs/ProgramListing";
import AddProgram from "../views/pages/Programs/AddProgram";
import EditProgram from "../views/pages/Programs/EditProgram";
import ViewProgram from "../views/pages/Programs/ViewProgram";
import TopicListing from "../views/pages/Program + Topic Listing/TopicListing";
import AddTopicProgram from "../views/pages/Program + Topic Listing/AddProgram";
import EditTopicProgram from "../views/pages/Program + Topic Listing/EditProgram";
import ProgramsTopic from "../views/pages/Program + Topic Listing/ProgramsTopic";
import ViewTopic from "../views/pages/Program + Topic Listing/ViewTopic";
import AddTopic from "../views/pages/Program + Topic Listing/AddTopic";
import EditTopic from "../views/pages/Program + Topic Listing/EditTopic";
import DietaryListing from "../views/pages/DietaryPreferences/DietaryListing";
import AddDietary from "../views/pages/DietaryPreferences/AddDietary";
import EditDietary from "../views/pages/DietaryPreferences/EditDietary";
import CuisineListing from "../views/pages/Cuisine/CuisineListing";
import AddCuisine from "../views/pages/Cuisine/AddCuisine";
import EditCuisine from "../views/pages/Cuisine/EditCuision";
import DietType from "../views/pages/DietType/DietType";
import AddDietType from "../views/pages/DietType/AddDietType";
import EditDietType from "../views/pages/DietType/EditDietType";
import RecipeListing from "../views/pages/Recipe/RecipeListing";
import AddRecipe from "../views/pages/Recipe/AddRecipe";
import ViewRecipe from "../views/pages/Recipe/ViewRecipe";
import EditRecipe from "../views/pages/Recipe/EditRecipe";
import WorkoutListing from "../views/pages/Workout/WorkoutListing";
import AddWorkout from "../views/pages/Workout/AddWorkout";
import EditWorkout from "../views/pages/Workout/EditWorkout";
import ExerciseListing from "../views/pages/Workout/ViewWorkoutCategory";
import ViewExerciseDetails from "../views/pages/Workout/ViewExerciseDetails";
import AddExercise from "../views/pages/Workout/AddExercise";
import EditExercise from "../views/pages/Workout/EditExercise";
import ForumListing from "../views/pages/ForumManagement/ForumListing";
import MyAccount from "../views/pages/MyAccount/MyAccount";
import EditAccount from "../views/pages/MyAccount/EditAccount";
import ChangePassword from "../views/pages/MyAccount/ChangePassword";
import StaticContentListing from "../views/pages/StaticContentManagement/StaticContentListing";
import PrivacyPolicy from "../views/pages/PrivacyPolicy/PrivacyPolicy";
import EditPrivacyPolicy from "../views/pages/PrivacyPolicy/EditPrivacyPolicy";
import AboutUs from "../views/pages/AboutUs/AboutUs";
import EditAboutUs from "../views/pages/AboutUs/EditAboutUs";
import TermsAndConditions from "../views/pages/T&C/T&C";
import EditTermsAndConditions from "../views/pages/T&C/EditT&C";
import FAQs from "../views/pages/FAQs/FAQs";
import AddFAQ from "../views/pages/FAQs/AddFAQ";
import EditFAQ from "../views/pages/FAQs/EditFAQ";
import BannerManagement from "../views/pages/Banner/BannerManagement";
import AddBanner from "../views/pages/Banner/AddBanner";
import EditBanner from "../views/pages/Banner/EditBanner";
import WorkoutSubCategory from "../views/pages/Workout/WorkoutSubcategory";
import AddSubcategory from "../views/pages/Workout/AddSubcategory";
import EditSubcategory from "../views/pages/Workout/EditSubcategory";
import ViewSubcategory from "../views/pages/Workout/ViewSubcategory";
import CouponListing from "../views/pages/Coupon Management/CouponListing";
import AddCoupon from "../views/pages/Coupon Management/AddCoupon";
import EditCoupon from "../views/pages/Coupon Management/EditCoupon";
// import AddTrimester from "../views/pages/PregnancyTrackerManagement/AddTrimester";
// import EditTrimester from "../views/pages/PregnancyTrackerManagement/EditTrimester";
import TrimesterListing from "../views/pages/PregnancyTrackerManagement/TrimesterListing";
// import AddWeekDetail from "../views/pages/PregnancyTrackerManagement/AddWeekDetails";
// import Trimester from "../views/pages/PregnancyTrackerManagement/TrimesterListing";
import TrimesterWeekListing from "../views/pages/PregnancyTrackerManagement/TrimesterWeekListing";
import AddWeekDetail from "../views/pages/PregnancyTrackerManagement/AddWeekDetails";
import ViewWeek from "../views/pages/PregnancyTrackerManagement/ViewWeek";
import EditActivation from "../views/pages/ActivateManagement/EditActivation";
import ListCardsBanners from "../views/pages/CardsBanner/ListCardsBanners";
import AddCards from "../views/pages/CardsBanner/AddCards";
import ViewCardsComponent from "../views/pages/CardsBanner/ViewCardsBanner";
import EditCards from "../views/pages/CardsBanner/EditeCards";
import ListKeyFeatures from "../views/pages/KeyFeatures/ListKeyFeatures";
import AddKeyFeatures from "../views/pages/KeyFeatures/AddKeyFeatures";
import ListClinicalResult from "../views/pages/ClinicalResult/ListClinicalResult";
import AddClinicalResult from "../views/pages/ClinicalResult/AddClinicalResult";
import EditClinicalResult from "../views/pages/ClinicalResult/EditClinicalResult";
import EditKeyFeatures from "../views/pages/KeyFeatures/EditKeyFeatures";
import ViewClinicalResult from "../views/pages/ClinicalResult/ViewClinicalResult";
import ListStatistics from "../views/pages/Statistics/ListStatistis";
import EditStatistics from "../views/pages/Statistics/EditStatistics";
import AddTestimonial from "../views/pages/Testimonial/AddTestimonial.jsx";
import EditTestimonial from "../views/pages/Testimonial/EditTestimonial.jsx";
import TestimonialManagement from "../views/pages/Testimonial/TestimonialManagement.jsx";
import ViewTestimonial from "../views/pages/Testimonial/ViewTestimonial.jsx";
import TicketListing from "../views/pages/TicketManagment/TicketListing";
import ViewTicket from "../views/pages/TicketManagment/ViewTicket";
import ListTeam from "../views/pages/Team/ListTeam";
import AddTeamMember from "../views/pages/Team/AddTeamMember";
import EditTeamMember from "../views/pages/Team/EditTeamMember";
import RecipeType from "../views/pages/RecipeType/RecipeType.jsx";

import AddRecipeType from "../views/pages/RecipeType/AddRecipeType.jsx";
import EditRecipeType from "../views/pages/RecipeType/UpdateRecipeType.jsx";
import ViewStatistics from "../views/pages/Statistics/ViewStatistics";
import AllPrograms from "../views/pages/AllPrograms/AllPrograms.jsx";



function AppRoutes() {
  return (
    <Routes>
      <Route path="/test" element={<LogoutModal />} />
      <Route path="/" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/verifyotp" element={<VerifyOTP />} />
      <Route path="/resetpassword" element={<ResetPassword />} />

      {/* Protected Routes */}
      <Route element={<AuthGuard />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/category" element={<Category />} />
        <Route path="/category/allPrograms" element={<AllPrograms/>} />

        <Route path="/category/addcategory" element={<AddCategory />} />
        <Route
          path="/category/editcategory"
          element={<EditCategory />}
        />
        <Route path="/program" element={<ProgramListing />} />
        <Route path="/program/addprogram" element={<AddProgram />} />
        <Route path="/program/editprogram" element={<EditProgram />} />
        <Route path="/program/viewprogram" element={<ViewProgram />} />
        <Route path="/topiclisting" element={<TopicListing />} />
        <Route path="/topiclisting/addprogram" element={<AddTopicProgram />} />
        <Route
          path="/topiclisting/editprogram"
          element={<EditTopicProgram />}
        />
        <Route path="/topiclisting/programsTopic" element={<ProgramsTopic />} />
        <Route path="/topiclisting/viewTopic" element={<ViewTopic />} />
        <Route path="/topiclisting/addTopic" element={<AddTopic />} />
        <Route path="/topiclisting/editTopic" element={<EditTopic />} />

        <Route path="/dietarylisting" element={<DietaryListing />} />
        <Route path="/dietarylisting/adddietary" element={<AddDietary />} />
        <Route path="/dietarylisting/editdietary" element={<EditDietary />} />
        <Route path="/cuisionlisting" element={<CuisineListing />} />
        <Route path="/cuisionlisting/addcuision" element={<AddCuisine />} />
        <Route path="/cuisionlisting/editcuision" element={<EditCuisine />} />
        <Route path="/diettype" element={<DietType />} />
        <Route path="/diettype/adddiettype" element={<AddDietType />} />
        <Route path="/diettype/editdiettype" element={<EditDietType />} />
        <Route path="/recipelisting" element={<RecipeListing />} />
        <Route path="/recipelisting/addrecipe" element={<AddRecipe />} />
        <Route path="/recipelisting/viewrecipe" element={<ViewRecipe />} />
        <Route path="/recipelisting/editrecipe" element={<EditRecipe />} />

        <Route path="/workoutlisting" element={<WorkoutListing />} />
        <Route path="/workoutlisting/addworkout" element={<AddWorkout />} />
        <Route path="/workoutlisting/EditWorkout/:id" element={<EditWorkout />} />
        
        <Route
          path="/workoutlisting/workoutSubcategory"
          element={<WorkoutSubCategory />}
        />
        <Route
          path="/workoutlisting/workoutSubcategory/addSubcategory"
          element={<AddSubcategory />}
        />
        <Route
          path="/workoutlisting/workoutSubcategory/editSubcategory"
          element={<EditSubcategory />}
        />
        <Route
          path="/workoutlisting/workoutSubcategory/viewSubcategory"
          element={<ViewSubcategory />}
        />
        <Route
          path="/workoutlisting/viewworkoutcategory/viewexercisedetails/:exerciseId"
          element={<ViewExerciseDetails />}
        />
        <Route
          path="/workoutlisting/viewworkoutcategory/addexercise/:workoutCategoryId"
          element={<AddExercise />}
        />
        <Route
          path="/workoutlisting/viewworkoutcategory/editexercise/:exerciseId"
          element={<EditExercise />}
        />
        <Route path="/forumlisting" element={<ForumListing />} />
        <Route path="/forumlisting" element={<ForumListing />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/myaccount/editaccount" element={<EditAccount />} />
        <Route path="/myaccount/changepassword" element={<ChangePassword />} />
        <Route path="/staticcontent" element={<StaticContentListing />} />
        <Route
          path="/staticcontent/privacypolicy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="/staticcontent/editprivacypolicy"
          element={<EditPrivacyPolicy />}
        />
        <Route path="/staticcontent/aboutus" element={<AboutUs />} />
        <Route path="/staticcontent/editaboutus" element={<EditAboutUs />} />
        <Route
          path="/staticcontent/termsandconditions"
          element={<TermsAndConditions />}
        />
        <Route
          path="/staticcontent/edittermsandconditions"
          element={<EditTermsAndConditions />}
        />
        <Route path="/staticcontent/faqs" element={<FAQs />} />
        <Route path="/staticcontent/faqs/addFAQ" element={<AddFAQ />} />
        <Route path="/staticcontent/faqs/editFAQ" element={<EditFAQ />} />
        <Route
          path="/staticcontent/bannermanagement"
          element={<BannerManagement />}
        />
        <Route
          path="/staticcontent/bannermanagement/addbanner"
          element={<AddBanner />}
        />
        <Route
          path="/staticcontent/bannermanagement/editbanner/:id"
          element={<EditBanner />}
        />
        <Route path="/bookingListing" element={<BookingListing />} />
        <Route path="/bookingListing/viewBooking" element={<ViewBooking />} />

        <Route path="/forums" element={<ForumListing />} />
        <Route path="/forums/viewforum/:forumId" element={<ViewForum />} />

        <Route
          path="/serviceAndSpecialization"
          element={<ListServiceAndSpecialization />}
        />
        <Route
          path="/serviceAndSpecialization/addServiceAndSpecialization"
          element={<AddServiceAndSpecialization />}
        />
        <Route
          path="/serviceAndSpecialization/editServiceAndSpecialization/:id"
          element={<EditServiceAndSpecialization />}
        />
        <Route path="/consults" element={<ConsultsListing />} />
        <Route path="/consults/addConsults" element={<AddConsults />} />
        <Route path="/consults/editConsult/:id" element={<EditConsult />} />
        <Route path="/consults/viewConsult/:id" element={<ViewConsult />} />
        <Route path="/subscriptionListing" element={<SubscriptionListing />} />
        <Route
          path="/subscriptionListing/addSubscription"
          element={<AddSubscription />}
        />
        <Route
          path="/subscriptionListing/editSubscription"
          element={<EditSubscription />}
        />
        <Route
          path="/subscriptionListing/viewSubscription"
          element={<ViewSubscription />}
        />

        <Route path="/mumListing" element={<MumListing />} />
        <Route path="/mumListing/viewmum" element={<ViewMum />} />

        {/* coupon routes */}
        <Route path="/couponListing" element={<CouponListing />} />
        <Route path="/couponListing/AddCoupon" element={<AddCoupon />} />
        <Route path="/couponListing/EditCoupon" element={<EditCoupon />} />
        <Route path="/couponListing/ViewCoupon" element={<ViewCoupon />} />
        <Route path="/bookingListing" element={<BookingListing />} />
        <Route path="/bookingListing/viewBooking" element={<ViewBooking />} />
        <Route path="/pregnancyTrackerListing" element={<TrimesterListing />} />

        <Route
          path="/pregnancyTrackerListing/TrimesterWeeks"
          element={<TrimesterWeekListing />}
        />
        <Route
          path="/pregnancyTrackerListing/TrimesterWeeks/addEditWeek"
          element={<AddWeekDetail />}
        />
        <Route
          path="/pregnancyTrackerListing/TrimesterWeeks/ViewWeek"
          element={<ViewWeek />}
        />
        <Route path="/activatelisting" element={<ActivateListing />} />
        <Route
          path="/activatelisting/addactivation"
          element={<AddActivation />}
        />
        <Route
          path="/pregnancyTrackerListing/trimesterListing"
          element={<TrimesterListing />}
        />
        <Route
          path="/activatelisting/viewActivation/:id"
          element={<ViewActivation />}
        />
        <Route
          path="/activatelisting/editActivation/:id"
          element={<EditActivation />}
        />
        <Route path="/optionlisting" element={<OptionListing />} />
        <Route path="/optionlisting/addoption" element={<AddOption />} />
        <Route path="/optionlisting/editoption" element={<EditOption />} />
        <Route
          path="/staticcontent/cardsBanner"
          element={<ListCardsBanners />}
        />
        <Route
          path="/staticcontent/cardsBanner/addCardBanner"
          element={<AddCards />}
        />
        <Route
          path="/staticcontent/cardsBanner/viewCardsBanner"
          element={<ViewCardsComponent />}
        />
        <Route
          path="/staticcontent/cardsBanner/editCardsBanner"
          element={<EditCards />}
        />
        <Route
          path="/staticcontent/listKeyFeatures"
          element={<ListKeyFeatures />}
        />
        <Route
          path="/staticcontent/listKeyFeatures/addKeyFeatures"
          element={<AddKeyFeatures />}
        />
        <Route
          path="/staticcontent/listKeyFeatures/editKeyFeatures"
          element={<EditKeyFeatures />}
        />
        <Route
          path="/staticcontent/listClinicalResult"
          element={<ListClinicalResult />}
        />
        <Route
          path="/staticcontent/listClinicalResult/addClinicalResult"
          element={<AddClinicalResult />}
        />
        <Route
          path="/staticcontent/listClinicalResult/editClinicalResult"
          element={<EditClinicalResult />}
        />
        <Route
          path="/staticcontent/listClinicalResult/viewClinicalResult"
          element={<ViewClinicalResult />}
        />
        <Route
          path="/staticcontent/listStatistics"
          element={<ListStatistics />}
        />
        <Route
          path="/staticcontent/listStatistics/editStatistics"
          element={<EditStatistics />}
        />
          <Route
          path="/staticcontent/listStatistics/viewStatistics"
          element={<ViewStatistics />}
        />
        <Route
          path="/staticcontent/testimonialmanagement"
          element={<TestimonialManagement />}
        />
        <Route
          path="/staticcontent/testimonialmanagement/addtestimonial"
          element={<AddTestimonial />}
        />
        <Route
          path="/staticcontent/testimonialmanagement/edittestimonial/:id"
          element={<EditTestimonial />}
        />
        <Route
          path="/staticcontent/testimonialmanagement/viewtestimonial/:id"
          element={<ViewTestimonial />}
        />
        <Route path="/ticketListing" element={<TicketListing />} />

        <Route path="/ticketListing/viewTicket" element={<ViewTicket />} />
        <Route path="/staticcontent/team" element={<ListTeam />} />
        <Route path="/staticcontent/team/addTeam" element={<AddTeamMember />} />
        <Route
          path="/staticcontent/team/editTeam"
          element={<EditTeamMember />}
        />
         <Route
          path="/recipeType"
          element={<RecipeType/>}
        />
         <Route
          path="/recipeType/AddRecipeType"
          element={<AddRecipeType/>}
        />
        <Route
          path="/recipeType/EditRecipeType"
          element={<EditRecipeType/>}
        />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
