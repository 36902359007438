import React, { useContext, useEffect, useState, useRef } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Divider,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
} from "@mui/material";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  //   DndProvider,
  DragDropContext,
  Draggable,
  Droppable,
} from "@hello-pangea/dnd";
import { DragHandle } from "@mui/icons-material";

import {
  listCategory,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import toast from "react-hot-toast";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  "Sr.No.",
  "Program Name",
  "Stage",
  "Created Date & Time",
  "Action",
];

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(225,140,168)",
    color: "white",
  },
  cellStyle: {
    textAlign: "left",
    // fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
    height: "49px",
    fontSize: "0.875rem",
  },

  tableStyle: {
    textAlign: "left",
    color: "white",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
  },
};
const stages = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const Category = () => {
  const tableRef = useRef(null);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false); // State to trigger re-render
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();

  const [totalDocs, setTotalDocs] = useState("");
  const [selectedStage, setSelectedStage] = useState(); // Default selected stage
  const [currentPage, setcurrentPage] = useState(1);
  const rowsPerPageOptions = [10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 12));
    setPage(0);
  };

  const fetchCategories = async () => {
    if (isAuthenticated && user) {
      try {
        const params = {
          limit: Number.MAX_SAFE_INTEGER,
          ...(selectedStage !== "All" && { stage: selectedStage }), // Conditionally add 'stage'
        };

        console.log(params);
        const response = await listCategory(params);

        setData(response.result.docs);
        // console.log(response.result.docs);
        setTotalDocs(response.result.totalDocs);
        setFilteredData(response.result.docs); // Initially, all data is filtered data

        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Reorder only the current page data (filteredData), not the entire dataset
    const currentPageData = [...filteredData];
    const [movedRow] = currentPageData.splice(source.index, 1);
    currentPageData.splice(destination.index, 0, movedRow);

    // Update the filteredData with the reordered rows
    setFilteredData(currentPageData);
    setData(currentPageData); // This ensures the data reflects the same changes

    // Prepare the reordered data to be sent to the database
    const updatedOrderArray = currentPageData.reverse().map((item, index) => ({
      categoryId: item._id,
      orderId: (index + 1).toString(),
    }));

    // Persist the new order to the database without triggering a full reload
    saveOrderToDB(updatedOrderArray);
  };

  const saveOrderToDB = async (updatedOrderArray) => {
    try {
      const data = { order: updatedOrderArray };

      const res = await putDataHandlerWithToken("updateCategoryOrder", data);
      if (res?.responseCode === 200) {
        toast.success("Order updated successfully!");
        fetchCategories();
      } else {
        toast.error("Failed to update order");
      }
    } catch (error) {
      console.error("Error saving order to the database:", error);
      toast.error("An error occurred while updating order");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [isAuthenticated, user, toggle, page, rowsPerPage, selectedStage]);

  const handleEdit = (id) => {
    navigate(`/category/editcategory`, { state: { categoryId: id } });
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleView = (id) => {
    navigate(`/category/allPrograms`, { state: { categoryId: id } });
  };

  const handleBlockConfirm = async () => {
    let categoryId = blockId;
    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockCategory",
        {},
        { categoryId }
      );
      if (res.responseCode === 200) {
        setToggle((prevToggle) => !prevToggle);
        setBlockModalOpen(false);
        console.log("blocked");
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = [...data]; // Copy the data to avoid mutation

    // Function to parse custom date
    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    // Apply search text filter
    if (filterCriteria.searchText) {
      const searchText = filterCriteria.searchText.toLowerCase().trim();
      filtered = filtered.filter((category) =>
        category.categoryName.toLowerCase().includes(searchText)
      );
    }

    // Apply from date filter
    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });
    }

    // Apply to date filter
    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });
    }

    // Apply stage filter
    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      filtered = filtered.filter(
        (category) => category.stage === filterCriteria.selectedValue
      );
    }

    // Apply status filter (Active, Blocked, All)

    // Set the filtered data to the state
    setFilteredData(filtered);
  };

  const downloadCSV = () => {
    // Define the headers for the CSV file
    const headers = ["Sr.No.", "Program Name", "Stage", "Created Date & Time"];

    // Map the data to extract only the relevant columns
    const csvRows = filteredData.map((row, index) => {
      const stageName =
        stages.find((option) => option.value === row.stage)?.key ||
        "Unknown Stage";
      const createdAt = new Date(row.createdAt).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      return [
        index + 1, // Sr.No.
        row.categoryName, // Program Name
        stageName, // Stage
        createdAt, // Created Date & Time
      ].join(",");
    });

    // Combine headers and rows for the final CSV content
    const csvContent = [headers.join(","), ...csvRows].join("\n");

    // Create a Blob and trigger the file download
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    const filename = `Program_List_${formattedDate}.csv`;

    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handlePageChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <DashboardLayout>
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Program Management
            </Typography>
          </Box>

          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={downloadCSV} />
              <BasicButtons
                text="+Add Program"
                onClick={() => navigate("/category/addcategory")}
              />
            </Box>
          </Box>
        </Box>
        <Divider />

        <Box className="filter-container">
          <FilterBox
            placeholder="Search by program name"
            stages={stages.map((stage) => stage.key)}
            onFilterChange={handleFilterChange}
            showAllOption={true}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
                width: "100%",
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "63vh", overflowY: "auto" }}
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  {filteredData?.length === 0 ? (
                    <DataNotFound />
                  ) : (
                    <Table sx={{ minWidth: 800 }}>
                      <TableHead>
                        <TableRow sx={PaginationTableStyle.rowStyle}>
                          {columns.map((e, i) => (
                            <TableCell
                              key={i}
                              align="left"
                              sx={PaginationTableStyle.tableStyle}
                            >
                              {e}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="programList">
                          {(provided) => (
                            <TableBody
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {filteredData?.map((item, i) => {
                                // Define stageKeys and stageText here, outside of JSX
                                const stageKeys = stages
                                  .filter((option) =>
                                    item.stage.includes(option.value)
                                  )
                                  .map((option) => option.key);
                                const stageText =
                                  stageKeys.join(", ") || "Unknown Stage";

                                return (
                                  <Draggable
                                    key={item?.id}
                                    draggableId={item?.id}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#faebf0"
                                            : "inherit",
                                          ...provided.draggableProps.style,
                                          width: "100%",
                                        }}
                                        sx={PaginationTableStyle.rowStyle}
                                      >
                                        <TableCell
                                          align="left"
                                          scope="row"
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {currentPage
                                            ? (currentPage - 1) * 10 + (i + 1)
                                            : i + 1}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.categoryName
                                            ? item?.categoryName
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {stageText.length > 20
                                            ? `${stageText.substring(0, 20)}...`
                                            : stageText}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.createdAt
                                            ? new Date(
                                                item?.createdAt
                                              ).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              })
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.categoryName
                                            ?.toLowerCase()
                                            .includes("recipe") ||
                                          item?.categoryName
                                            ?.toLowerCase()
                                            .includes("workout") ? (
                                            // Render a blocked icon or disable the view icon
                                            <Tooltip title="Manage this program through the management options available in the sidebar.">
                                              <VisibilityOffIcon
                                                sx={{
                                                  color: "rgb(225,140,168)",
                                                  borderColor: "rgb(225,140,168)",
                                                  cursor: "not-allowed",
                                                }}
                                              />
                                            </Tooltip>
                                          ) : (
                                            // Render the normal view icon
                                            <VisibilityIcon
                                              variant="outlined"
                                              onClick={() =>
                                                handleView(item?._id)
                                              }
                                              sx={{
                                                color: "rgb(225,140,168)",
                                                borderColor: "rgb(225,140,168)",
                                                cursor: "pointer",
                                              }}
                                            />
                                          )}

                                          <EditIcon
                                            onClick={() => handleEdit(item._id)}
                                            sx={{
                                              color: "rgb(225,140,168)",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily:
                                                "'Poppins', sans-serif",
                                              cursor: "pointer",
                                              ml: 2,
                                              mr: 2,
                                            }}
                                          />
                                          <BlockIcon
                                            onClick={() =>
                                              handleBlock(
                                                item._id,
                                                item.workoutCategoryName,
                                                item.status
                                              )
                                            }
                                            sx={{
                                              color:
                                                item.status === "ACTIVE"
                                                  ? "green"
                                                  : "red",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily:
                                                "'Poppins', sans-serif",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <DragHandle sx={{ ml: 4 }} />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </TableBody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                  )}
                </>
              )}
              {/* 
              {shouldShowPagination && (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={filteredData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    "& .MuiTablePagination-selectIcon": {
                      color: "rgb(225,140,168)",
                    },
                  }}
                />
              )} */}
            </TableContainer>
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="PROGRAM"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default Category;
