import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography, Divider } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import AlertDialog from "../../../components/AlertDialog";
import "../../../scss/main.css";
import {
  getDataHandler,
  deleteDataHandler,
  getDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../Modal/DeleteModal";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "title", label: "Title" },
  { id: "percentages", label: "Percentages" },
  { id: "created_at", label: "Created At" },
  { id: "action", label: "Action" },
];

const ListClinicalResult = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [clinicalResults, setClinicalResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data when component mounts
    getDataHandlerWithToken("getAllClinicalresults")
      .then((response) => {
        if (response?.responseCode === 200) {
          setClinicalResults(response.result); // Assuming response.result contains the list of clinical results
        } else {
          toast.error(
            response?.responseMessage || "Failed to fetch clinical results"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch clinical results");
        setLoading(false);
      });
  }, []);

  const handleView = (clinicalResult) => {
    navigate(`/staticcontent/listClinicalResult/viewClinicalResult`, {
      state: { clinicalResult },
    });
  };

  const handleEdit = (resultData) => {
    navigate(`/staticcontent/listClinicalResult/editClinicalResult`, {
      state: { resultData },
    });
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedRow) return;

    try {
      const response = await deleteDataHandler("deleteClinicalResult", {
        resultId: selectedRow._id,
      });
      if (response?.responseCode === 200) {
        toast.success("Clinical result deleted successfully");
        setClinicalResults(
          clinicalResults.filter((item) => item._id !== selectedRow._id)
        );
      } else {
        toast.error(
          response?.responseMessage || "Failed to delete clinical result"
        );
      }
    } catch (error) {
      console.error("Error deleting clinical result", error);
      toast.error("Failed to delete clinical result");
    } finally {
      setOpen(false);
    }
  };

  return (
    <DashboardLayout
      sectionName="Clinical Results Management"
      title="Clinical Results Management"
    >
      <Box className="heading-container-top">
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/staticcontent")}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>
              Clinical Results Management
            </Typography>
          </Box>
        </Box>

        <Box className="button-container">
          <BasicButtons
            text="+Add Clinical Result"
            onClick={() =>
              navigate("/staticcontent/listClinicalResult/addClinicalResult")
            }
          />
        </Box>
      </Box>
      <br />
      <Divider />
      <Box className="StaticScreenContainer">
        <Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Loading/> 
            </Box>
          ): clinicalResults?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={clinicalResults.map((item, index) => ({
                sr_no: index + 1,
                title: item.title,
                percentages: item.percentages + "%",
                created_at: moment(item.createdAt).format("MM/DD/YYYY, h:mm A"),
                action: (
                  <Box className="row-button-container">
                    <VisibilityIcon
                      variant="outlined"
                      onClick={() => handleView(item)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />
                    <EditIcon
                      variant="outlined"
                      onClick={() => handleEdit(item)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />
                    <DeleteIcon
                      variant="outlined"
                      onClick={() => handleClickOpen(item)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {open && (
        <DeleteModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDeleteConfirm}
          name={selectedRow?.title}
          description={`Are you sure you want to delete the clinical result titled ""? This action cannot be undone.`}
        />
      )}
    </DashboardLayout>
  );
};

export default ListClinicalResult;
