import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import DefaultImg from "../../../assets/DefaultImage.png";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { editAccountSchema } from "../../../schema";
import {
  postDataHandler,
  postDataHandlerWithToken,
  getDataHandlerWithToken,
} from "../../../apiconfig/service";
import AdminImg from "../../../assets/profileimg-h2m.png";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import EditIcon from "@mui/icons-material/Edit";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";
import { modalStyles } from "../../../scss/style"; // Ensure this path is correct
import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";
import "../../../scss/main.css";

const accountStyle = {
  editAccountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  accountTitle: {
    ...typographyStyles.h4,
  },
  accountLabel: {
    ...typographyStyles.h6,
  },
  editAccountContent: {
    display: "flex",
    paddingTop: "20px",
  },
  editAccountImgSection: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  },
  editAccountInfoSection: {
    width: "60%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  editAccountinfoBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  editAccountBtnSection: {
    padding: "0 20%",
    display: "flex",
    justifyContent: "center",
  },
};

const EditAccount = () => {
  const [profileImage, setProfileImage] = useState(DefaultImg);
  const [admin, setAdmin] = useState();
  const [profileImageURL, setProfileImageURL] = useState(null);
  const [loading, setLoading] = useState(false);

  // States for cropping functionality
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await getDataHandlerWithToken("viewProfile");
      if (res.responseCode === 200) {
        setAdmin(res?.result);
        setProfileImage(res?.result.profilePicture);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const initialValues = {
    firstName: admin?.fullName ? admin?.fullName.split(" ")[0] : "",
    lastName: admin?.fullName ? admin?.fullName.split(" ")[1] : "",
    email: admin?.email ? admin?.email : "",
  };

  const handleFormSubmit = async (values) => {
    const data = {
      fullName: `${values.firstName.trim()} ${values.lastName.trim()}`.trim(),

      profilePicture: profileImageURL ? profileImageURL : admin.profilePicture,
    };
    try {
      if (!loading) {
        setLoading(true);
        const res = await postDataHandlerWithToken("updateProfile", data);
        if (res?.responseCode === 200) {
          toast.success(res?.responseMessage);
          let user = JSON.parse(sessionStorage.getItem("user"));
          user.profilePicture = profileImageURL
            ? profileImageURL
            : admin.profilePicture;

            


            user.fullName = values.firstName
            ? values.firstName
            : admin.fullName;

         
          sessionStorage.setItem("user", JSON.stringify(user));
          navigate("/myaccount");
        } else {
          toast.error(res?.data?.responseMessage);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: editAccountSchema,
      enableReinitialize: true,
      onSubmit: (values) => handleFormSubmit(values),
    });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/jpg",
    ];

    if (!file || !validImageTypes.includes(file.type)) {
      toast.error("Please upload a valid image format (JPEG, PNG, WebP, JPG).");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
      setIsModalOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = useCallback(async () => {
    setLoading(true);
    try {
      const cloudinaryUrl = await cropAndUploadImage(
        selectedImage,
        croppedAreaPixels
      );
      setCroppedImage(cloudinaryUrl);
      setProfileImageURL(cloudinaryUrl);
      setProfileImage(cloudinaryUrl);
      setIsModalOpen(false);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    }
    setLoading(false);
  }, [croppedAreaPixels, selectedImage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImage(null);
  };

  return (
    <DashboardLayout sectionName="My Account" title="Edit Profile">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Account</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={accountStyle.editAccountContainer}>
        <form style={accountStyle.editAccountContent} onSubmit={handleSubmit}>
          <Box sx={accountStyle.editAccountImgSection}>
            <label
              htmlFor="upload-image"
              className="profile-img-upload-label"
              style={{ position: "relative", display: "inline-block" }}
            >
              {loading && (
                <CircularProgress
                  size={60}
                  sx={{
                    color: "rgb(225, 140, 168)",
                    position: "absolute",
                    bottom: "35%",
                    right: "35%",
                    borderRadius: "50%",
                  }}
                />
              )}
              <img
                src={profileImage || AdminImg}
                alt="ProfileImg"
                className="profile-img"
                style={{ borderRadius: "50%" }} // Ensuring image is displayed circular
              />
              <input
                type="file"
                id="upload-image"
                acceptedFile="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <EditIcon
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 26,
                  background: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </label>
          </Box>
          <Box sx={accountStyle.editAccountInfoSection}>
            <Box sx={accountStyle.editAccountinfoBox}>
              <Typography sx={accountStyle.accountLabel}>First Name</Typography>
              <InputField
                className="authTextField"
                name="firstName"
                type="text"
                value={values.firstName}
                maxLength={50}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the first name"
                onKeyDown={(event) => {
                  if (event.key === " ") {
                    event.preventDefault();
                  }
                }}
              />
              {errors.firstName && touched.firstName && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {errors.firstName}
                </p>
              )}
            </Box>
            <Box sx={accountStyle.editAccountinfoBox}>
              <Typography sx={accountStyle.accountLabel}>Last Name</Typography>
              <InputField
                className="authTextField"
                name="lastName"
                type="text"
                value={values.lastName}
                maxLength={50}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the last name"
                onKeyDown={(event) => {
                  if (event.key === " ") {
                    event.preventDefault();
                  }
                }}
              />
              {errors.lastName && touched.lastName && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {errors.lastName}
                </p>
              )}
            </Box>
            <Box sx={accountStyle.editAccountinfoBox}>
              <Typography sx={accountStyle.accountLabel}>
                Email Address
              </Typography>
              <InputField
                className="authTextField"
                name="email"
                type="email"
                value={values.email}
                disabled
                placeholder="Enter email"
                inputProps={{ style: { cursor: "not-allowed" } }}
              />
            </Box>
            <Box sx={accountStyle.editAccountBtnSection}>
              <BasicButtons text="Save Changes" type="submit" />
            </Box>
          </Box>
        </form>
      </Box>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <div style={modalStyles.cropperWrapper}>
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1} // Set aspect ratio to 1 for circular crop
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default EditAccount;
