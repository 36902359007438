import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Divider,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
} from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";
import backBtn from "../../../assets/backBtn.svg";

import {
  //   DndProvider,
  DragDropContext,
  Draggable,
  Droppable,
} from "@hello-pangea/dnd";
import { DragHandle } from "@mui/icons-material";

const columns = [
  "Sr.No.",
  "Section Name",
  "Program Name",
  "Stage",

  "Created Date & Time",
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];
const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(225,140,168)",
    color: "white",
  },
  cellStyle: {
    textAlign: "left",
    // fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
    height: "49px",
    fontSize: "0.875rem",
  },

  tableStyle: {
    textAlign: "left",
    color: "white",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
  },
};

const AllPrograms = () => {
  const [programList, setProgramList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [render, setRender] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const rowsPerPageOptions = [10, 25];

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const location= useLocation();

  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        categoryId:location?.state?.categoryId,
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("listProgramByCategoryId", query);
      console.log("API response:", res);

      if (res.responseCode === 200) {
        setProgramList(res?.result?.docs || []);

   
        setFilteredData(res?.result?.docs || []);

        // Set no data if there are no programs
        res?.result?.docs?.length === 0 ? setNoData(true) : setNoData(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Reorder only the current page data (filteredData), not the entire dataset
    const currentPageData = [...filteredData];
    const [movedRow] = currentPageData.splice(source.index, 1);
    currentPageData.splice(destination.index, 0, movedRow);

    // Update the filteredData with the reordered rows
    setFilteredData(currentPageData);
    setProgramList(currentPageData);

    // Prepare the reordered data to be sent to the database, ensuring global indices are updated
    const updatedOrderArray = currentPageData.reverse().map((item, index) => ({
      programId: item._id,
      orderId: (index + 1).toString(),
    }));

    console.log("Reordered data to update:", updatedOrderArray);

    // Persist the new order to the database
    saveOrderToDB(updatedOrderArray);
  };

  const saveOrderToDB = async (updatedOrderArray) => {
    try {
      const data = { order: updatedOrderArray };

      const res = await putDataHandlerWithToken("updateProgramOrder", data);
      if (res?.responseCode === 200) {
        toast.success("Order updated successfully!");
        fetchData();
      } else {
        toast.error("Failed to update order");
      }
    } catch (error) {
      console.error("Error saving order to the database:", error);
      toast.error("An error occurred while updating order");
    }
  };

  useEffect(() => {
    fetchData();
    //fetchTopic()
  }, [render, toggle]);

  const handleFilterChange = (filterCriteria) => {
    let filtered = programList;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.programName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter((category) =>
        category.stage.includes(filterCriteria.selectedValue)
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };

  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{
                  // // width: "2.813",
                  // height: "5.813",
                  cursor: "pointer",
                  gap: "10px",
                }}
                onClick={() => navigate(-1)}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>
              Sections
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Search by section name"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <TableContainer className="removeScrollbar" component={Paper}>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {filteredData?.length === 0 ? (
                    <DataNotFound />
                  ) : (
                    <Table sx={{ minWidth: 800 }}>
                      <TableHead>
                        <TableRow sx={PaginationTableStyle.rowStyle}>
                          {columns.map((e, i) => (
                            <TableCell
                              key={i}
                              align="left"
                              sx={PaginationTableStyle.tableStyle}
                            >
                              {e}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="programList">
                          {(provided) => (
                            <TableBody
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {filteredData
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((item, i) => (
                                  <Draggable
                                    key={item?.id}
                                    draggableId={item?.id}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={i}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#faebf0"
                                            : "inherit",
                                          ...provided.draggableProps.style,
                                          width: "100%",
                                        }}
                                      >
                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {i + 1}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.programName
                                            ? item?.programName
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.categoryName
                                            ? item?.categoryName
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {
                                            // Find the corresponding key for the stage value
                                            se.find(
                                              (stage) =>
                                                stage.value === item.stage
                                            )?.key || "Unknown Stage" // Fallback in case no match is found
                                          }
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            ...PaginationTableStyle.cellStyle,
                                            position: "relative",
                                          }}
                                        >
                                          {item?.createdAt
                                            ? new Date(
                                                item?.createdAt
                                              ).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              })
                                            : "NA"}
                                          <DragHandle
                                            sx={{
                                              position: "absolute",
                                              right: 10, // Adjust this value as needed
                                              top: "50%",
                                              transform: "translateY(-50%)",
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </TableBody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                  )}
                </>
              )}
            </TableContainer>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default AllPrograms;
