import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import RecipeListing from "../Recipe/RecipeListing";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "mum_name", label: "User Name" },
  { id: "stage", label: "Stage" },

  { id: "email", label: "User Email" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];
const stages = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];
const MumListing = () => {
  const Navigate = useNavigate();
  const [mumList, setMumList] = useState([]);
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("getMumList", query);
      if (res.responseCode === 200) {
        const sortedData = (res?.result?.users || res?.result?.docs || []).sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setMumList(sortedData);
        setFilteredData(sortedData);
        setMumList(res?.result?.users || res?.result?.docs || []);
        setFilteredData(res?.result?.users || res?.result?.docs || []);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [render]);

  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    try {
      const query = { userId: id };
      const res = await putDataHandlerWithToken("blockUnblockMum", {}, query);
      if (res.responseCode === 200) {
        const updatedMumList = mumList.map((mum) =>
          mum._id === id ? { ...mum, status: status } : mum
        );
        setMumList(updatedMumList);
        setFilteredData(updatedMumList);
        setBlockModalOpen(false);
        toast.success(res.responseMessage);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {}
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleView = (id) => {
    // Handle view action

    Navigate(`/mumlisting/viewmum`, { state: { userId: id } });
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = mumList;

    // Filter by search text
    if (filterCriteria.searchText) {
      const searchText = filterCriteria.searchText.toLowerCase().trim();

      filtered = filtered.filter(
        (category) =>
          (category.fullName &&
            category.fullName.toLowerCase().includes(searchText)) ||
          (category.email &&
            category.email.toLowerCase().includes(searchText)) ||
          (category.phoneNumber && category.phoneNumber.includes(searchText))
      );
    }

    // Custom date parsing function
    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    // Filter by from date
    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);

      filtered = filtered.filter((user) => {
        const createdAtDate = new Date(user.createdAt);
        return createdAtDate >= fromDate;
      });
    }

    // Filter by to date
    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);

      filtered = filtered.filter((user) => {
        const createdAtDate = new Date(user.createdAt);
        return createdAtDate <= toDate;
      });
    }

    // Filter by stage (selectedValue)
    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      filtered = filtered.filter(
        (category) => category.stage === filterCriteria.selectedValue
      );
    }

    // Filter by status (Active, Blocked, All)
    if (filterCriteria.status && filterCriteria.status !== "All") {
      filtered = filtered.filter(
        (category) => category.status === filterCriteria.status
      );
    }

    setFilteredData(filtered);
  };

  const handleDownloadCSV = () => {
    // Extract headers based on the columns displayed in the table
    const headers = ["Sr.No.", "User Name", "Stage", "Email","Created Date & Time","Status"];
  
    // Map filtered data to match the table's displayed data
    const dataToExport = filteredData.map((row, index) => ({
      sr_no: index + 1,
      mum_name: row.fullName,
      stage: stages.find((stage) => stage.value === row.stage)?.key || "Unknown Stage",
      email: row.email,
      created_date_time: new Date(row.createdAt).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      status: row.status,
    }));
  
    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";
  
    // Append rows of data based on columns
    csvContent += dataToExport
      .map((row) =>
        columns
          .map((col) => {
            const value = row[col.id];
            return typeof value === "string" && value.includes(",")
              ? `"${value}"` // Escape commas in CSV values
              : value;
          })
          .join(",")
      )
      .join("\n");
  
    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
  
    // Create the filename
    const filename = `User_Listing_${formattedDate}.csv`;
  
    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };
  
  return (
    <DashboardLayout sectionName="Mum Management" showBackButtonSection={false}>
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              User Listing
            </Typography>
          </Box>
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Search by username/email"
            onFilterChange={handleFilterChange}
            showStatusDropdown={true}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData?.map((row, index) => ({
                sr_no: index + 1,
                mum_name: (
                  <Tooltip title={row.fullName} arrow>
                    <span>
                      {row.fullName.length > 20
                        ? `${row.fullName.substring(0, 20)}...`
                        : row.fullName}
                    </span>
                  </Tooltip>
                ),
                stage: (
                  <Tooltip
                    title={
                      stages.find((option) => option.value === row.stage)
                        ?.key || "Unknown Stage"
                    }
                    arrow
                  >
                    <span>
                      {(
                        stages.find((option) => option.value === row.stage)
                          ?.key || "Unknown Stage"
                      ).length > 20
                        ? `${(
                            stages.find((option) => option.value === row.stage)
                              ?.key || "Unknown Stage"
                          ).substring(0, 20)}...`
                        : stages.find((option) => option.value === row.stage)
                            ?.key || "Unknown Stage"}
                    </span>
                  </Tooltip>
                ),

                email: (
                  <Tooltip title={row.email} arrow>
                    <span>
                      {row.email.length > 20
                        ? `${row.email.substring(0, 20)}...`
                        : row.email}
                    </span>
                  </Tooltip>
                ),
                created_date_time: new Date(row.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                ),
                status: row.status,
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <VisibilityIcon
                      onClick={() => handleView(row._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        cursor: "pointer",
                      }}
                    />
                    <BlockIcon
                      onClick={() =>
                        handleBlock(row._id, row.fullName, row.status)
                      }
                      sx={{
                        color: row.status == "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="USER"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default MumListing;
