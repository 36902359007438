import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import typographyStyles from "../../../theme/Typography";
import { subscriptionSchema } from "../../../schema";
import backBtn from "../../../assets/backBtn.svg";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import toast from "react-hot-toast";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "600",
    fontSize: "18px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
  },
};

const dropdownOptions = ["Monthly", "6 Months", "Yearly"];
const durationOptions = [
  { key: "Monthly", value: "MONTHLY" },
  { key: "6 Months", value: "SIXMONTH" },
  { key: "Yearly", value: "YEARLY" },
];

const AddSubscription = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    subscriptionName: "",
    price: "",
    discount: "",
    duration: "",
    StripePriceId: "",
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: subscriptionSchema,
    onSubmit: async (values) => {
      
      // Your submit logic here
      const data={
        subscriptionName:values.subscriptionName,
        subscriptionDuration: durationOptions.find((option) => option.key == values.duration).value,
        subscriptionPrice:values.price,
        subscriptionDiscount:values.discount,
        stripePriceId:values.StripePriceId,

      }

      console.log(data);
      try {
        setLoading(true);
        const response = await postDataHandlerWithToken("addUpdateSubscription", data);
        if (response.responseCode === 200) {
          toast.success("Subscription added successfully.");
          navigate(-1);
        } else {
          toast.error(response.data.responseMessage);
        }
      } catch (error) {
        console.error("Error adding coupon:", error);
        toast.error("Failed to add coupon. Please try again later.");
      } finally {
        setLoading(false);
      }
      



     
    },
  });

  const handleDropdownChange = (value) => {
    setFieldValue("duration", value);
  };
  const handlePriceChange = (event) => {
    let { value } = event.target;
  
    // Remove any non-numeric characters
    value = value.replace(/[^0-9]/g, '');
  
    // If the input value is longer than 5 characters, prevent further input
    if (value.length > 10) {
      event.preventDefault(); // Stop the input
      return; // Exit the function
    }
  
    // Convert to a number and ensure it is non-negative
    const numericValue = Math.max(0, Math.floor(Number(value)));
    
    // Set value in the field, capping at the max limit
    setFieldValue("price", numericValue > 9999999999 ? 9999999999 : numericValue);
  };
  
  
  const handleDiscountChange = (event) => {
    let { value } = event.target;
  
    // Remove any non-numeric characters and ensure the value is a whole number
    value = value.replace(/[^0-9]/g, '');
  
    // Convert to a number and ensure it is non-negative
    const numericValue = Math.max(0, Math.floor(Number(value)));
    if (numericValue > 100) return setFieldValue("discount", 100); // Maximum discount limit
  
    setFieldValue("discount", numericValue);
  };
  

  return (
    <DashboardLayout sectionName="Add Subscription" title="Subscription">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="Back Button"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={typographyStyles.h4}>
            Add Subscription
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Subscription Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="subscriptionName"
                  value={values.subscriptionName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter subscription name"
                  maxLength={30}
                />
                {errors.subscriptionName && touched.subscriptionName && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.subscriptionName}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Price (in $):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="price"
                  value={values.price}
                  onChange={handlePriceChange}
                  onBlur={handleBlur}
                  placeholder="Enter price"
                />
                {errors.price && touched.price && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.price}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Discount (%):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="discount"
                  value={values.discount}
                  onChange={handleDiscountChange}
                  onBlur={handleBlur}
                  placeholder="Enter discount"
                />
                {errors.discount && touched.discount && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.discount}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select duration:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="duration"
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={values.duration}
                  placeholder="Select duration"
                  onChange={handleDropdownChange}
                />
                {errors.duration && touched.duration && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.duration}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                Stripe Price Id(From Stripe Dashboard):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="StripePriceId"
                  value={values.StripePriceId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter stripe price id"
                />
                {errors.StripePriceId && touched.StripePriceId && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.StripePriceId}
                  </p>
                )}
              </Box>
            </Box>
            <Box
              className="add-edit-button-section"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <BasicButtons
                text="Add Subscription"
                disabled={loading}
                name="submit"
                type="submit"
                width="25%"
                loading={loading}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddSubscription;
