import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addNewCategorySchema, dietTypeSchema } from "../../../schema";
import "../../../scss/main.css";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditDietType = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [saveas, setSaveas] = useState("FREE");
  const [thumbnail, setThumbnail] = useState(null);
  const [dietData, setDietData] = useState();
  const [dietId, setDietId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    formik.setFieldValue("selectStage", value);
  };

  const Navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const dietIdFromLocation = location.state?.dietId;
    setDietId(dietIdFromLocation);
    console.log("ifff", dietIdFromLocation);
    const query = {
      dietId: dietIdFromLocation,
    };
    try {
      const res = await getDataHandlerWithToken("getDiet", query);
      if (res.responseCode === 200) {
        const response = res.result;
        console.log("xdfgdfgdfsg", response);
        setDietData(response);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state?.dietId) {
      fetchProgram();
    }
  }, [location.state?.dietId]);

  const initialValues = {
    selectStage: dietData?.stage || [],
    dietName: dietData?.dietName || "",
    // saveas: dietData?.saveAs || "FREE",
  };
  console.log("ifff", location.state?.dietId);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: dietTypeSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        setLoading(true);
        const data = {
          dietId: dietId,
          dietName: values.dietName,
          stage: values.selectStage,
          // saveAs: values.saveas,
        };
        const response = await postDataHandlerWithToken("addUpdateDiet", data);
        toast.success("Diet type updated successfully");
        Navigate(-1);
      } catch (error) {
        toast.error("Failed to update diet type.");
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <DashboardLayout sectionName="Diet Type Management" title="Edit Diet Type">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => Navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Edit Diet Type
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="selectStage"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={se}
                  value={formik.values.selectStage}
                  onChange={(value) =>
                    formik.handleChange({
                      target: { name: "selectStage", value },
                    })
                  }
                />
                {formik.errors.selectStage && formik.touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Diet Type Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="dietName"
                  value={formik.values.dietName}
                  maxLength={50}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter diet type name"
                />
                {formik.errors.dietName && formik.touched.dietName ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.dietName}
                  </p>
                ) : null}
              </Box>
            </Box>
            {/* <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Save As*:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    name="saveas"
                    value={formik.values.saveas}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setSaveas(e.target.value);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                      className="radio"
                      value="FREE"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      className="radio"
                      value="LOCKED"
                      control={<Radio />}
                      label="Locked (will be accessed after subscription)"
                    />
                  </RadioGroup>
                </FormControl>
                {formik.errors.saveas && formik.touched.saveas ? (
                  <p style={{ color: "red", marginTop: "-5px", float: "left" }}>
                    {formik.errors.saveas}
                  </p>
                ) : null}
              </Box>
            </Box> */}
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Edit Diet Type"
              name="submit"
              type="submit"
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditDietType;
