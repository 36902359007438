import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  listBanner,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "stage", label: "Stage" },
  { id: "diet_type", label: "Diet Type" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const DietType = () => {
  const [dietData, setDietData] = useState([]);
  const [render, setRender] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);

  const Navigate = useNavigate();

  const handleEdit = (id) => {
    // Handle edit action

    Navigate("/diettype/editdiettype", { state: { dietId: id } });
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    // Handle block action
    const query = {
      dietId: id,
    };

    try {
      const res = await putDataHandlerWithToken("blockUnblockdiet", {}, query);
      console.log(res);
      if (res.responseCode === 200) {
        const updatedProgramList = dietData.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setDietData(updatedProgramList);
        setFilteredData(updatedProgramList);
        setBlockModalOpen(false);
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setBlockModalOpen(false);
      toast.error(error);
    }

    console.log("Block button clicked for row id:", id);
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  useEffect(() => {
    const query = {
      limit: Number.MAX_SAFE_INTEGER,
    };
    // Fetch data when component mounts
    listBanner("getDietList", query)
      .then((response) => {
        setLoading(true);
        console.log(loading);
        if (response?.responseCode === 200) {
          setDietData(response.result.docs || []); // Assuming response.data.data contains the list of FAQs
          setFilteredData(response?.result?.docs || []);
          setLoading(false);
        } else {
          setFilteredData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch diet  data");
        setLoading(false);
      });
  }, [render]);
  const handleFilterChange = (filterCriteria) => {
    let filtered = dietData;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.dietName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter((category) =>
        category.stage.includes(filterCriteria.selectedValue)
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = dietData.map(({ _id, ...rest }) => rest);
    const headers = Object.keys(dataWithoutId[0]);

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    const filename = `dietType_list_${formattedDate}.csv`;
    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };

  return (
    <DashboardLayout
      sectionName="Diet Type Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Diet Type Listing
            </Typography>
          </Box>

          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Diet Type"
                onClick={() => {
                  Navigate("/diettype/adddiettype");
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Search by diet type"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((row, index) => {
                const stageKeys = se
                  .filter((option) => row.stage.includes(option.value))
                  .map((item) => item.key);
                const stageText = stageKeys.join(", ") || "Unknown Stage";
                return {
                  sr_no: index + 1,
                  stage: (
                    <Tooltip title={stageText} arrow>
                      <span>
                        {stageText.length > 20
                          ? `${stageText.substring(0, 20)}...`
                          : stageText}
                      </span>
                    </Tooltip>
                  ),
                  diet_type: (
                    <Tooltip title={row.dietName} arrow>
                      <span>
                        {row.dietName.length > 20
                          ? `${row.dietName.substring(0, 20)}...`
                          : row.dietName}
                      </span>
                    </Tooltip>
                  ),
                  created_date_time: new Date(row.createdAt).toLocaleString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",

                      hour12: true,
                    }
                  ),

                  action: (
                    <Box className="row-button-container" sx={{ gap: "15%" }}>
                      <EditIcon
                        onClick={() => handleEdit(row._id)}
                        sx={{
                          color: "rgb(225,140,168)",
                          borderColor: "rgb(225,140,168)",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                        }}
                      />

                      <BlockIcon
                        variant="outlined"
                        onClick={() =>
                          handleBlock(row._id, row.dietName, row.status)
                        }
                        sx={{
                          color: row.status == "ACTIVE" ? "green" : "red",
                          borderColor: "rgb(225,140,168)",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ),
                };
              })}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="DIET TYPE"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default DietType;
