import React from "react";
import { Box, Typography, Divider, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import BasicButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import typographyStyles from "../../../theme/Typography";
import backBtn from "../../../assets/backBtn.svg";

const AddFAQ = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Please enter the question."),
      answer: Yup.string().required("Please enter the answer."),
    }),
    onSubmit: async (values) => {
      try {
        const data = {
          contentType: "FAQ",
          title: "faq",
          question: values.question,
          answer: values.answer,
        };

        const response = await postDataHandlerWithToken("addStaticContent", data);
        toast.success("Question and answer were added");

        navigate("/staticcontent/faqs"); // Navigate to FAQs page after successful addition
      } catch (error) {
        console.error("Error adding question and answer:", error);
        toast.error(error.message || "An error occurred");
      }
    },
  });

  return (
    <DashboardLayout sectionName="Static Content Management" title="Add FAQ">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add FAQs</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="StaticScreenContainer">
        <Box className="StaticScreenContent">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ marginBottom: 4 }}>
              <Typography>Enter the question</Typography>
              <TextField
                fullWidth
                name="question"
                placeholder="Enter the question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ maxLength: 150 }}
                error={formik.touched.question && Boolean(formik.errors.question)}
                sx={{ marginBottom: '8px' }}
              />
              {formik.touched.question && formik.errors.question && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {formik.errors.question}
                </p>
              )}
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <Typography>Enter the answer</Typography>
              <TextField
                fullWidth
                name="answer"
                placeholder="Enter the answer"
                value={formik.values.answer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                rows={10}
                inputProps={{ maxLength: 1000 }}
                error={formik.touched.answer && Boolean(formik.errors.answer)}
                sx={{ marginBottom: '8px' }}
              />
              {formik.touched.answer && formik.errors.answer && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {formik.errors.answer}
                </p>
              )}
            </Box>
            <Box className="StaticBtnContainer" sx={{ marginBottom: 2 }}>
              <BasicButton text="Add" type="submit" width={"20%"} />
            </Box>
          </form>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default AddFAQ;
