import React, { useEffect } from 'react';

const DyntubePlayer = ({videoKey}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.dyntube.com/v1.0/dyntube.js';
    script.async = true;

    script.onload = () => {
      if (!window._dyntube_v1_init) {
        window._dyntube_v1_init = true;
      }
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div data-dyntube-key={videoKey}></div>
      <style>{`
        .dyt-poster, video {
          background-color: white !important;
        }
      `}</style>
    </div>
  );
};

export default DyntubePlayer;