import React from 'react';
import { Home as HomeIcon, AccountCircle as AccountCircleIcon, Settings as SettingsIcon } from '@mui/icons-material';

const sidebarData = [
  { text: 'Home', icon: <HomeIcon /> },
  { text: 'Profile', icon: <AccountCircleIcon /> },
  { text: 'Settings', icon: <SettingsIcon /> },
];


export const VIDEO_ENC="13120427"

export default sidebarData;
