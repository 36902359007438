import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "../../../schema";
import "../../../scss/main.css";
import InputField from "../../../components/InputField";
import { postDataHandler } from "../../../apiconfig/service";
import { AuthContext } from "../../../context/AuthContext";
import { toast } from "react-hot-toast";

const authStyle = {
  authHeading: {
    textAlign: "center",
    ...typographyStyles.h3,
  },
  authLabel: {
    ...typographyStyles.h6,
  },
  forgotPasswordLabel: {
    ...typographyStyles.h6,
    color: "#3996d2",
    cursor: "pointer",
  },
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(
    window.localStorage.getItem("password") != null
  );

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    console.log(isChecked);
  };

  const navigate = useNavigate();
  const { login, isAuthenticated, user } = useContext(AuthContext);

  // Initialize form values based on isChecked state
  const initialValues = {
    email: window.localStorage.getItem("email") || "",
    password: window.localStorage.getItem("password") || "",
  };

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          const response = await postDataHandler("adminLogin", values);
          if (response.responseCode === 200) {
            login(response.result);
            toast.success(response.responseMessage);
            navigate("/dashboard");

            // Store email and password based on isChecked
            if (isChecked) {
              window.localStorage.setItem("email", values.email);
              window.localStorage.setItem("password", values.password);
              console.log("removed");
            } else {
              window.localStorage.removeItem("email");
              window.localStorage.removeItem("password");
            }
          } else {
            toast.error(response.data.responseMessage);
          }
        } catch (error) {
          console.error("Login failed", error);
        } finally {
          setLoading(false);
        }
      },
    });

  return (
    <LoginLayout>
      <Box
        className="authBox"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <form className="authContainer" onSubmit={handleSubmit}>
          <Box>
            <Typography style={authStyle.authHeading}>Admin Login</Typography>
          </Box>
          <Box className="authContent">
            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                Email
              </Typography>
              <InputField
                className="authTextField"
                name="email"
                type="text"
                value={values.email}
                maxLength={321}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the email address"
              />

              {errors.email && touched.email && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {errors.email}
                </p>
              )}
            </Box>

            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                Password
              </Typography>
              <InputField
                className="authTextField"
                type="password"
                name="password"
                value={values.password}
                maxLength={20}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the password"
                restrictCopyPaste={true}
              />
              {errors.password && touched.password && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {errors.password}
                </p>
              )}

              <Box className="checkboxField">
                <Box className="rememberMeField">
                  <input
                    type="checkbox"
                    className="rememberMe"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="remember-me" style={authStyle.authLabel}>
                    Remember Me
                  </label>
                </Box>
                <Box
                  style={authStyle.forgotPasswordLabel}
                  onClick={() => {
                    navigate("/forgotpassword");
                  }}
                >
                  Forgot Password?
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="authFields" sx={{ alignItems: "center" }}>
            <BasicButtons
              text="Login"
              name="login"
              type="submit"
              loading={loading}
              width={"35%"}
            />
          </Box>
        </form>
      </Box>
    </LoginLayout>
  );
};

export default Login;
