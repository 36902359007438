import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { addNewCategorySchema, cuisineSchema } from "../../../schema";
import backBtn from "../../../assets/backBtn.svg";

import "../../../scss/main.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	CodeBlock,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	GeneralHtmlSupport,
	Heading,
	HorizontalLine,
	Indent,
	IndentBlock,
	Italic,
	Link,
	Paragraph,
	SelectAll,
	Style,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	Underline,
	Undo
} from 'ckeditor5';
import { toast } from "react-hot-toast";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },

  addEditLabel: {
    ...typographyStyles.h6,
  },

  radioContainer: {
    display: "flex",
  },

  // imagePreview: {
  //     marginTop: '10px',
  //     objectFit: 'cover',
  //     border: '2px solid gray',
  // }
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditCuisine = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [categoryType, setCategoryType] = useState("free");
  const [thumbnail, setThumbnail] = useState(null);
  const [cuisine, setCuisine] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const fetchCuisine = async () => {
    const query = {
      cuisineId: location.state?.cuisineId,
    };
    try {
      const res = await getDataHandlerWithToken("viewCuisine", query);
      if (res.responseCode === 200) {
        setCuisine(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCuisine();
  }, []);

  const initialValues = {
    selectStage: cuisine?.stage || [],
    cuisineName: cuisine?.cuisineName || "",
    saveAs: cuisine?.saveAs || "",
  };

  const handleCategoryTypeChange = (event) => {
    setFieldValue("saveAs", event.target.value);
  };

  const handleFormSubmit = async (values) => {
    const data = {
      cuisineId: location?.state?.cuisineId,
      stage: values.selectStage,
      cuisineName: values.cuisineName,
      saveAs: values.saveAs,
    };
    try {
      setLoading(true);
      const res = await postDataHandlerWithToken("addUpdateCuisine", data);
      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        navigate("/cuisionlisting");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: cuisineSchema,
    enableReinitialize: true,

    onSubmit: (values) => handleFormSubmit(values),
  });

  const [editorContent, setEditorContent] = useState("");

  return (
    <DashboardLayout
      sectionName="Cuisine Preference Management"
      title="Edit Cuisine"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Cuisine</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="selectStage"
                  // value={values.selectRange}
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={se}
                  value={values.selectStage}
                  onChange={(value) =>
                    handleChange({ target: { name: "selectStage", value } })
                  }
                />
                {errors.selectStage && touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "-5px", float: "left" }}>
                    {errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Cuisine Name
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="cuisineName"
                  value={values.cuisineName}
                  maxLength={50}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter cuisine name"
                />
                {errors.cuisineName && touched.cuisineName ? (
                  <p style={{ color: "red", marginTop: "0px", float: "left" }}>
                    {errors.cuisineName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Save As:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    name="saveAs"
                    value={values.saveAs}
                    onChange={handleCategoryTypeChange}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                      className="radio"
                      value="FREE"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      className="radio"
                      value="LOCKED"
                      control={<Radio />}
                      label="Locked (will be accessed after subscription)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Edit Cuisine"
              name="submit"
              type="submit"
              loading={loading}
              width={"20%"}
            />
            {/* <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => {
                navigate("/cuisionlisting");
              }}
            /> */}
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditCuisine;
