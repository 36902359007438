import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";

import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../../schema";
import { postDataHandler } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import InputField from "../../../components/InputField";

const authStyle = {
  authHeading: {
    textAlign: "center",
    ...typographyStyles.h3,
  },

  authSubHeading: {
    ...typographyStyles.h6,
    color: "#0009",
    textAlign: "center",
  },

  authLabel: {
    ...typographyStyles.h6,
  },

  forBack: {
    ...typographyStyles.h6,
    color: "#3996d2",
    textAlign: "center",
    cursor: "pointer",
  },
};

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  const initialValues = {
    email: "",
  };

  const navigate = useNavigate();
  const handleFormSubmit = async (values) => {
  
    const data = {
      email: values.email.trim().toLowerCase(),
    };
    try {
      setIsLoading(true);

      const res = await postDataHandler("resendOTPAdmin", data);
      
      if (res?.responseCode === 200) {

        toast.success(res?.responseMessage);
        console.log(res)
        // auth.checkLogin(res?.token);
        localStorage.setItem("otpTimeLeft", 180);
        navigate("/verifyOTP", { state: { email: values.email } });

        setIsLoading(false);
      } else {
        setErrorMessage(res?.data?.responseMessage);
        toast.error(res?.data?.responseMessage);
        
        setIsLoading(false);
      }
      //console.log("removed");
    } catch (error) {
      setIsLoading(false);
    }
  };

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: forgotPasswordSchema,
      onSubmit: (values) => handleFormSubmit(values),
    });
  return (
    <LoginLayout>
      <Box className="authBox" sx={{ display: 'flex', justifyContent:"center"}}>
        <form className="authContainer" onSubmit={handleSubmit}>
          <Box className="authHeadingBox">
            <Typography style={authStyle.authHeading}>
              Forgot Your Password ?
            </Typography>
            <Typography style={authStyle.authSubHeading}>
              Please enter your registered email here and we will send OTP to
              reset your password.
            </Typography>
          </Box>
          <Box className="authContent">
            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                Email
              </Typography>
              <InputField
                className="authTextField"
                name="email"
                type="text"
                value={values.email}
                maxLength={321}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the email address"
              />
              {errors.email && touched.email ? (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {errors.email}
                </p>
              ) : null}
            </Box>
          </Box>
          <Box className="authFields" sx={{alignItems:"center"}}>
            <BasicButtons text="Send" name="send" type="submit" loading={isLoading} width={"35%"}/>
          </Box>
          <Typography
            style={authStyle.forBack}
            onClick={() => {
              navigate("/");
            }}
          >
            Back to login
          </Typography>
        </form>
      </Box>
    </LoginLayout>
  );
};

export default ForgotPassword;
