import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import * as Yup from "yup";
import { Box, Typography, Divider } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
};

const dropdownOptions = ["Language", "Exercise", "Other than a mum", "Other"];
const se = [
  { key: "Language", value: "LANGUAGE" },
  { key: "Exercise", value: "EXERCISE" },
  { key: "Other than a mum", value: "OTHERTHANMUM" },
  { key: "Other", value: "OTHER" },
];

const EditOption = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { obj } = state || {};

  const validationSchema = Yup.object({
    optionList: Yup.string().required("Please select an option."),
    optionName: Yup.string().trim().required("Please enter option name."),
  });

  const formik = useFormik({
    initialValues: {
      optionList: obj
        ? se.find((option) => option.value === obj.optionType)?.key || ""
        : "",
      optionName: obj?.optionName || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        optionId: obj._id,
        optionType:
          se.find((option) => option.key === values.optionList)?.value || "",
        optionName: values.optionName,
      };

      try {
        setLoading(true);
        const res = await postDataHandlerWithToken("addUpdateOption", data);

        if (res?.responseCode === 200) {
          toast.success(res?.responseMessage);
          navigate("/optionlisting");
        } else {
          toast.error(res?.data?.responseMessage);
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to add option.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <DashboardLayout
      sectionName="Option Preference Management"
      title="Edit Option"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Option</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Option List:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="optionList"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={formik.values.optionList}
                  onChange={(value) =>
                    formik.setFieldValue("optionList", value)
                  }
                  placeholder="Select option"
                />
                {formik.errors.optionList && formik.touched.optionList ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.optionList}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Option Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="optionName"
                  value={formik.values.optionName}
                  maxLength={30}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter option name"
                />
                {formik.errors.optionName && formik.touched.optionName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.optionName}
                  </p>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Update option"
              name="submit"
              type="submit"
              width={"20%"}
              disabled={formik.isSubmitting}
              loading={loading}
            />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditOption;
