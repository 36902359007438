import React, { useState } from "react";
import PropTypes from "prop-types";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

const InputField = ({
  type,
  name,
  placeholder,
  label,
  color,
  rows,
  backgroundColor,
  borderRadius,
  width,
  height,
  border,
  onChange,
  onBlur,
  maxLength,
  value,
  onPointerLeave,
  inputProps,
  acceptedFile,
  restrictCopyPaste, // New prop for restricting copy-paste
  accept, // Add accept prop
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [fileName, setFileName] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }else{
      setFileName("")
    }
    onChange(event);
  };

  const handleCopyPaste = (event) => {
    if (restrictCopyPaste) {
      event.preventDefault();
    }
  };

  return (
    <label style={{ display: "block", width: "100%" }}>
      {type === "file" ? (
        <input
          type="file"
          hidden
          name={name}
          onChange={handleFileChange}
          onBlur={onBlur}
          accept={acceptedFile}
        />
      ) : null}
      <TextField
        type={
          type === "password"
            ? showPassword
              ? "text"
              : "password"
            : type === "file"
            ? "text"
            : type
        }
        placeholder={placeholder}
         multiline={!!rows} 
        rows={rows}
        name={name}
        sx={{
          "& .MuiInputBase-root": {
            height: rows ? 'auto' : '46px', // Fixed height if no rows, otherwise auto
          },
          "& .MuiSvgIcon-root": {
            color: "rgb(225, 140, 168) !important",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
           
          },
        }}
        
        onPointerLeave={onPointerLeave}
        value={type === "file" ? "" : value}
        onChange={type !== "file" ? onChange : null}
        onBlur={onBlur}
        inputProps={{
          maxLength: maxLength,
          ...inputProps,
          readOnly: type === "file",
        }}
        onCopy={handleCopyPaste} // Prevent copying
        onCut={handleCopyPaste} // Prevent cutting
        onPaste={handleCopyPaste} // Prevent pasting
        InputProps={{
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {!showPassword ? (
                    <VisibilityOff style={{ fontSize: "30px" }} />
                  ) : (
                    <Visibility style={{ fontSize: "30px" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null,
          startAdornment:
            type === "file" ? (
              <InputAdornment position="start">
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddPhotoAlternateIcon style={{ fontSize: "30px" }} />
                  <span
                    style={{
                      maxWidth: "200px", // Limit the width
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      marginLeft: "8px",
                    }}
                  >
                    {fileName || "Choose file"}
                  </span>
                </div>
              </InputAdornment>
            ) : null,
          // inputProps,
        }}
        style={{
          cursor: type == "file" ? "pointer" : "",
          color: color,
          backgroundColor: backgroundColor,
          borderRadius: borderRadius,
          border: "none",
          outline: "none",
          width: "100%",
          height: height,
          boxSizing: "border-box",
          fontSize: "14px",
          fontWeight: "300",
          fontStyle: "none",
          fontFamily: "Outfit",
        }}
      />
    </label>
  );
};

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  height: PropTypes.string,
  border: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  onPointerLeave: PropTypes.func,
  inputProps: PropTypes.object,
  acceptedFile: PropTypes.string,
  restrictCopyPaste: PropTypes.bool, // New prop type
  accept: PropTypes.string, // Add accept prop type
};

export default InputField;
