import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider } from "@mui/material";
import BasicButtons from "../../../components/Button";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	CodeBlock,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	GeneralHtmlSupport,
	Heading,
	HorizontalLine,
	Indent,
	IndentBlock,
	Italic,
	Link,
	Paragraph,
	SelectAll,
  List,
  ListProperties,
	TextTransformation,
	Underline,
	Undo
} from 'ckeditor5';
import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  getDataHandler,
  patchTokenDataHandler,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";

const editorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "style",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "bulletedList",
      "numberedList",

      "|",
      "horizontalLine",
      "link",
      "insertTable",
      "blockQuote",
      "codeBlock",
      "|",
      "alignment",
      "|",
      "outdent",
      "indent",
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    Indent,
    IndentBlock,
    Italic,
    Link,
    Paragraph,
    SelectAll,
    List,
    ListProperties,

    TextTransformation,
    Underline,
    Undo,
  ],
  fontFamily: {
    supportAllValues: true,
  },
  fontSize: {
    options: [10, 12, 14, "default", 18, 20, 22],
    supportAllValues: true,
  },
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true,
      },
    ],
  },

  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: "https://",
    decorators: {
      toggleDownloadable: {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "file",
        },
      },
    },
  },
  placeholder: "Type or paste your content here!",
};

const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const EditTermsAndConditions = () => {
  const [editorContent, setEditorContent] = useState("");
  const [staticContentId, setStaticContentId] = useState("");
  const [title, setTitle] = useState("T&C");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data when component mounts
    getDataHandler("getAllstaticContentByType", {
      contentType: "TERMSCONDITION",
    })
      .then((response) => {
        if (response?.responseCode === 200 && response.result.length > 0) {
          const content = response.result[0];
          setEditorContent(content.description);
          setStaticContentId(content._id);
          setTitle(content.title);
          console.log(content)
        } else {
          toast.error(
            // response?.responseMessage || "Failed to fetch Terms and Conditions"
          );
        }
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch Terms and Conditions");
      });
  }, []);

  const handleUpdate = async () => {
    console.log("clicked");
    const updatedContent = {
      contentType: "TERMSCONDITION",
      description: editorContent,
      staticContentId: staticContentId,
      title: title,
    };

    try {
      const response = await patchTokenDataHandler(
        "updateStaticContent",
        updatedContent
      );
      if (response?.responseCode === 200) {
        toast.success("Terms and Conditions updated successfully");
        navigate("/staticcontent");
      } else {
        toast.error(
          response?.responseMessage || "Failed to update Terms and Conditions"
        );
      }
    } catch (error) {
      console.error("Error in updating data", error);
      toast.error("Failed to update Terms and Conditions");
    }
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Edit Terms & Conditions"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Edit Terms And Conditions
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="StaticScreenContainer">
        <Box className="StaticEditorContainer ">
          <CKEditor
            editor={ClassicEditor}
            data={editorContent}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorContent(data);
            }}
            config={editorConfig}
            className="StaticEditor"
          />
        </Box>
        <Box className="StaticBtnContainer">
          <BasicButtons
            text="Update"
            name="submit"
            type="submit"
            onClick={handleUpdate}
            width={"20%"}
          />
          {/* <BasicButtons
            text="Back"
            onClick={() => {
              navigate("/staticcontent");
            }}
          /> */}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default EditTermsAndConditions;
