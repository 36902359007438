import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Divider,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
} from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";
import {
  //   DndProvider,
  DragDropContext,
  Draggable,
  Droppable,
} from "@hello-pangea/dnd";
import { DragHandle } from "@mui/icons-material";

const columns = [
  "Sr.No.",
  "Section Name",
  "Program Name",
  "Stage",

  "Created Date & Time",
  "Action",
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];
const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(225,140,168)",
    color: "white",
  },
  cellStyle: {
    textAlign: "left",
    // fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
    height: "49px",
    fontSize: "0.875rem",
  },

  tableStyle: {
    textAlign: "left",
    color: "white",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Poppins",
    padding: "4px",
    paddingLeft: "20px",
  },
};

const TopicListing = () => {
  const [programList, setProgramList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [render, setRender] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const rowsPerPageOptions = [10, 25];

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("listProgram", query);
      console.log("API response:", res);

      if (res.responseCode === 200) {
        setProgramList(res?.result?.docs || []);

        // Filter out programs where contentDetails is an empty array or not present
        const filteredPrograms = res?.result?.docs.filter(
          (program) =>
            Array.isArray(program.contentDetails) &&
            program.contentDetails.length === 0
        );
        setFilteredData(filteredPrograms);

        // Set no data if there are no programs
        res?.result?.docs?.length === 0 ? setNoData(true) : setNoData(false);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchTopic = async () => {
  //   try {
  //     const res = await getDataHandlerWithToken("listTopic");
  //     console.log("API response:", res);
  //     if (res.responseCode === 200) {
  //       setTopicList(res?.result?.docs || []);
  //     } else {
  //       toast.error(res?.data?.responseMessage);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Reorder only the current page data (filteredData), not the entire dataset
    const currentPageData = [...filteredData];
    const [movedRow] = currentPageData.splice(source.index, 1);
    currentPageData.splice(destination.index, 0, movedRow);

    // Update the filteredData with the reordered rows
    setFilteredData(currentPageData);
    setProgramList(currentPageData);

    // Prepare the reordered data to be sent to the database, ensuring global indices are updated
    const updatedOrderArray = currentPageData.reverse().map((item, index) => ({
      programId: item._id,
      orderId: (index + 1).toString(),
    }));

    console.log("Reordered data to update:", updatedOrderArray);

    // Persist the new order to the database
    saveOrderToDB(updatedOrderArray);
  };

  const saveOrderToDB = async (updatedOrderArray) => {
    try {
      const data = { order: updatedOrderArray };

      const res = await putDataHandlerWithToken("updateProgramOrder", data);
      if (res?.responseCode === 200) {
        toast.success("Order updated successfully!");
        fetchData();
      } else {
        toast.error("Failed to update order");
      }
    } catch (error) {
      console.error("Error saving order to the database:", error);
      toast.error("An error occurred while updating order");
    }
  };

  useEffect(() => {
    fetchData();
    //fetchTopic()
  }, [render, toggle]);

  const handleFilterChange = (filterCriteria) => {
    let filtered = programList;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.programName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter((category) =>
        category.stage.includes(filterCriteria.selectedValue)
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };

  const handleView = (id) => {
    navigate("/topiclisting/programsTopic", { state: { programId: id } });
  };

  const handleEdit = (id) => {
    navigate("/topiclisting/editprogram", { state: { programId: id } });
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    const query = {
      programId: id,
    };

    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockProgram",
        {},
        query
      );
      console.log(res);
      if (res.responseCode === 200) {
        const updatedProgramList = filteredData.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setProgramList(updatedProgramList);
        setFilteredData(updatedProgramList);
        setBlockModalOpen(false);

        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
    console.log("Block button clicked for row id:", id);
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = programList.map(({ _id, ...rest }) => rest);
    const headers = [
      "Sr.No.",
      "Section Name",
      "Program Name",
      "Stage",
      "Created Date & Time",
    ];

    // Map filtered data to match the table's displayed data
    const dataToExport = dataWithoutId.map((row, index) => ({
      sr_no: index + 1,
      program_name: row.programName,
      category_name: row.categoryName,
      stage:
        se.find((stage) => stage.value === row.stage)?.key || "Unknown Stage",

      created_date_time: new Date(row.createdAt).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      status: row.status,
    }));

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataToExport
      .map((row) =>
        columns
          .map((col) => {
            const value = row[col.id];
            return typeof value === "string" && value.includes(",")
              ? `"${value}"` // Escape commas in CSV values
              : value;
          })
          .join(",")
      )
      .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `Section+Topic_List_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };

  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Section + Topic Listing
            </Typography>
          </Box>

          {/* {showBackButtonSection && (
                  <Box sx={dashboardLayoutStyle.titleSection}>
                  <img
                      src={backBtn}
                      alt="backBtn"
                      style={{
                          width: "2.813",
                          height: "2.813",
                          cursor: "pointer",
                      }}
                      onClick={() => Navigate(-1)}
                  />
                  <Typography sx={dashboardLayoutStyle.sectionTitle}>{title}</Typography>
              </Box>
              )} */}
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Section"
                onClick={() => {
                  navigate("/topiclisting/addprogram");
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Search by section name"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <TableContainer className="removeScrollbar" component={Paper}>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {filteredData?.length === 0 ? (
                    <DataNotFound />
                  ) : (
                    <Table sx={{ minWidth: 800 }}>
                      <TableHead>
                        <TableRow sx={PaginationTableStyle.rowStyle}>
                          {columns.map((e, i) => (
                            <TableCell
                              key={i}
                              align="left"
                              sx={PaginationTableStyle.tableStyle}
                            >
                              {e}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="programList">
                          {(provided) => (
                            <TableBody
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {filteredData
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((item, i) => (
                                  <Draggable
                                    key={item?.id}
                                    draggableId={item?.id}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={i}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#faebf0"
                                            : "inherit",
                                          ...provided.draggableProps.style,
                                          width: "100%",
                                        }}
                                      >
                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {i + 1}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.programName
                                            ? item?.programName
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.categoryName
                                            ? item?.categoryName
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {
                                            // Find the corresponding key for the stage value
                                            se.find(
                                              (stage) =>
                                                stage.value === item.stage
                                            )?.key || "Unknown Stage" // Fallback in case no match is found
                                          }
                                        </TableCell>

                                       

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          {item?.createdAt
                                            ? new Date(
                                                item?.createdAt
                                              ).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              })
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          sx={PaginationTableStyle.cellStyle}
                                        >
                                          <VisibilityIcon
                                            variant="outlined"
                                            onClick={() =>
                                              handleView(item?._id)
                                            }
                                            sx={{
                                              color: "rgb(225,140,168)",
                                              borderColor: "rgb(225,140,168)",
                                              cursor: "pointer",
                                            }}
                                          />

                                          <EditIcon
                                            onClick={() =>
                                              handleEdit(item?._id)
                                            }
                                            sx={{
                                              color: "rgb(225,140,168)",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily:
                                                "'Poppins', sans-serif",
                                              cursor: "pointer",
                                              marginLeft: 2,
                                              marginRight: 2,
                                            }}
                                          />

                                          <BlockIcon
                                            onClick={() =>
                                              handleBlock(
                                                item?._id,
                                                item?.programName,
                                                item?.status
                                              )
                                            }
                                            sx={{
                                              color:
                                                item?.status === "ACTIVE"
                                                  ? "green"
                                                  : "red",
                                              borderColor: "rgb(225,140,168)",
                                              fontFamily:
                                                "'Poppins', sans-serif",
                                              cursor: "pointer",
                                            }}
                                          />

                                          <DragHandle sx={{ ml: 4 }} />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </TableBody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                  )}
                </>
              )}

              {/* {shouldShowPagination && (
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={filteredData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      "& .MuiTablePagination-selectIcon": {
                        color: "rgb(225,140,168)",
                      },
                    }}
                  />
                )} */}
            </TableContainer>
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="Section"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default TopicListing;
