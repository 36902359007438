import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../scss/main.css";
import { Box, Grid } from "@mui/material";
import dayjs from "dayjs";

export default function ResponsiveDatePickers({
  value,
  onChange,
  placeholder,
  minDate,
  maxDate
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          "DatePicker",
          "MobileDatePicker",
          "DesktopDatePicker",
          "StaticDatePicker",
        ]}
      >
        <DemoItem>
          <DatePicker
            className="customDatePickerIcon"
            sx={{
              "& .MuiSvgIcon-root": {
                color: "rgb(225, 140, 168) !important",
              },
              width: "100%",
              "& .MuiInputBase-root": {
                height: "46px", // Set the desired custom height
              },
              "& .MuiInputBase-input": {
                // Ensures the text input inside spans the entire height
                // Adjust padding as needed for vertical centering
                // height: "80%", // Ensures the text input spans the entire height
                boxSizing: "border-box", // Include padding and borders in the element's height/width
                padding: "0 20px", // Adjusted padding to center text vertically
                display: "flex",
                alignItems: "center", // Vertically center text and placeholder
                
              },


              "&  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root":{
                lineHeight:"14px !important"
              }
            }}
            label={placeholder}
            value={value}
            onChange={(newValue) => onChange(newValue)}
            minDate={minDate ? dayjs(minDate) : undefined}
            maxDate={maxDate ? dayjs(maxDate) : dayjs()}
            fullWidth
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
