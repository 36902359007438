import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Searchbar from "./Searchbar";
import ResponsiveDatePickers from "./DatePicker";
import Dropdown from "./Dropdown";
import Button from "./Button";
import toast from "react-hot-toast";
import { getDataHandlerWithToken } from "../apiconfig/service";

const se = [
  { key: "All", value: "All" },
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum", value: "POSTPARTUM" },
  { key: "Beyond", value: "BEYOND" },
];

const statusOptions = [
  { key: "All", value: "All" },
  { key: "Active", value: "ACTIVE" },
  { key: "Blocked", value: "BLOCK" },
];

const FilterBox = ({
  placeholder,
  onFilterChange,
  showAllOption = true,
  dropdownNone,
  showStatusDropdown = false, 
  showRecipeTypeDropdown=false
}) => {
  const [searchText, setSearchText] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  
  const [selectedValue, setSelectedValue] = useState(
    showAllOption ? "All" : "Trying to Conceive"
  );
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [recipeType, setRecipeType] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState("All"); // Initialize with "All"

  const dropdownOptions = showAllOption
    ? ["All", "Trying to Conceive", "Pregnant", "Postpartum", "Beyond"]
    : ["Trying to Conceive", "Pregnant", "Postpartum", "Beyond"];

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    applyFilters(value);
  };

  const handleRecipeChange = (value) => {
    setSelectedRecipe(value);
    applyFilters(selectedValue, value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    applyFilters(selectedValue, selectedRecipe, value);
  };

  const applyFilters = (value = selectedValue, recipeValue = selectedRecipe, statusValue = selectedStatus) => {
    onFilterChange({
      searchText,
      fromDate,
      toDate,
      selectedValue: se.find((option) => option.key === value)?.value,
      status: statusOptions.find((option) => option.key === statusValue)?.value,
      recipeType: recipeValue === "All" ? null : recipeValue // Adjust logic for recipeType
    });
  };

  const handleClear = () => {
    setSearchText("");
    setFromDate(null);
    setToDate(null);
    setSelectedValue(showAllOption ? "All" : "Trying to Conceive");
    setSelectedStatus("All");
    setSelectedRecipe("All"); // Reset to "All"
    applyFilters(); // Apply filters with cleared values
  };

  useEffect(() => {
    const fetchRecipeType = async () => {
      try {
        const res = await getDataHandlerWithToken("listRecipeType");
        if (res.responseCode === 200) {
          const transformedData = res?.result?.recipeTypes?.map((item) => item.recipeType);
          setRecipeType(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchRecipeType();
  }, []);

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12} sm={6} md={3}>
        <Searchbar
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            applyFilters(selectedValue, selectedRecipe, selectedStatus);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <ResponsiveDatePickers
          placeholder={"From date"}
          value={fromDate}
          onChange={(date) => {
            setFromDate(date);
            applyFilters(selectedValue, selectedRecipe, selectedStatus);
          }}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <ResponsiveDatePickers
          placeholder={"To date"}
          value={toDate}
          onChange={(date) => {
            setToDate(date);
            applyFilters(selectedValue, selectedRecipe, selectedStatus);
          }}
          minDate={fromDate}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={showStatusDropdown || showRecipeTypeDropdown ? 1 : 2}>
        <Dropdown
          options={dropdownOptions}
          value={selectedValue}
          onChange={handleDropdownChange}
          none={dropdownNone}
        />
      </Grid>
      {showStatusDropdown && (
        <Grid item xs={12} sm={6} md={1}>
          <Dropdown
            options={statusOptions.map((opt) => opt.key)}
            value={selectedStatus}
            onChange={handleStatusChange}
          />
        </Grid>
      )}
      {showRecipeTypeDropdown && (
        <Grid item xs={12} sm={6} md={1}>
          <Dropdown
            options={["All", ...recipeType]} // Ensure "All" is included
            value={selectedRecipe}
            onChange={handleRecipeChange}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={1}>
        <Button text="Clear" onClick={handleClear} />
      </Grid>
    </Grid>
  );
};

export default FilterBox;
