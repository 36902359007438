import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider, Card, CardMedia } from "@mui/material";
import * as Yup from "yup";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import { topicProgram } from "../../../schema";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";

import { modalStyles } from "../../../scss/style"; // Ensure this path is correct
import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const AddTopicProgram = () => {
  const [selectedValue, setSelectedValue] = useState("Select Stage");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null); // New state for image preview
  const [loading, setLoading] = useState(false);
  const [mydata, setMydata] = useState([]);
  // States for cropping functionality
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const Navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await getDataHandlerWithToken("listCategory", {
        limit: Number.MAX_SAFE_INTEGER,
      });
     
      console.log("API response:", res);
      if (res.responseCode === 200) {
        setMydata(res?.result?.docs || []);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterCategoriesByStage = (selectedValue) => {
    const stageValue = se.find((option) => option.key === selectedValue)?.value;
    if (stageValue) {
      const filteredCategories = mydata.filter(
        (category) =>
          category.stage === stageValue &&
          category.categoryName != "Recipes" &&
          category.categoryName != "Workout" &&
          category.status !== "BLOCK"
      );
      setCategoryList(filteredCategories);
    }
  };

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    setSelectedCategory("");
    setCategoryId("");
    formik.setFieldValue("categoryName", "");
    filterCategoriesByStage(value);
  };

  const handleCategoryChange = (value) => {
    const selectedCategoryName = value;
    setSelectedCategory(selectedCategoryName);
    const selectedCategoryObj = categoryList.find(
      (obj) => obj.categoryName === selectedCategoryName
    );
    if (selectedCategoryObj) {
      setCategoryId(selectedCategoryObj._id);
      console.log("Selected Category Object:", selectedCategoryObj);
    }
  };

  const validationSchema = topicProgram;

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));

      setIsModalOpen(true);
    }
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Image cropping functions
  const handleCrop = useCallback(async () => {
    setLoading(true);
    try {
      const cloudinaryUrl = await cropAndUploadImage(
        selectedImage,
        croppedAreaPixels
      );
      setCroppedImage(cloudinaryUrl);
      setSelectedImage(cloudinaryUrl);
      setThumbnail(cloudinaryUrl);
      setThumbnailPreview(cloudinaryUrl);
      setIsModalOpen(false);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    }
    setLoading(false);
  }, [croppedAreaPixels, selectedImage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImage(null);
    setThumbnail(null);
  };

  const handleFormSubmit = async (values, event) => {
    const data = {
      stage: se.find((option) => option.key == selectedValue).value,
      categoryId: categoryId,
      programName: values.programName,
      programThumbnailImage: croppedImage,
    };
    try {
      const res = await postDataHandlerWithToken("addUpdateProgram", data);
      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        Navigate("/topiclisting");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    filterCategoriesByStage(selectedValue);
  }, [selectedValue]);

  const formik = useFormik({
    initialValues: {
      selectStage: "",
      categoryName: "",
      programName: "",
      categoryImage: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Add Section"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => Navigate("/topiclisting")}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Section</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="selectStage"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={formik.values.selectStage}
                  placeholder="Select stage"
                  onChange={(value) => {
                    formik.setFieldValue("selectStage", value);
                    handleDropdownChange(value);
                  }}
                />
                {formik.errors.selectStage && formik.touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Program:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="categoryName"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={categoryList.map((obj) => obj.categoryName)}
                  value={formik.values.categoryName}
                  placeholder="Select program"
                  onChange={(value) => {
                    formik.setFieldValue("categoryName", value);
                    handleCategoryChange(value);
                  }}
                />
                {formik.errors.categoryName && formik.touched.categoryName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.categoryName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Section Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="programName"
                  value={formik.values.programName}
                  maxLength={100}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter section name"
                />
                {formik.errors.programName && formik.touched.programName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.programName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Section Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  acceptedFile="image/*"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "categoryImage",
                      event.currentTarget.files[0]
                    );
                    handleThumbnailChange(event);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.categoryImage && formik.touched.categoryImage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.categoryImage}
                  </p>
                ) : null}
                {thumbnailPreview && (
                  <Box>
                    <Card
                      sx={{ maxWidth: 300, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnailPreview} />
                    </Card>
                  </Box>
                )}
                  
                
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text={"Add Section"}
              name="submit"
              type="submit"
              width={"20%"}
              disabled={loading}
              loading={loading}
            />
          </Box>
        </form>
      </Box>
      {/* Image Cropper Modal */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={21/9}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default AddTopicProgram;
